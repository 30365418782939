import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SurveyCreatorModel } from "survey-creator-core";
import {ActivatedRoute} from "@angular/router";


// const creatorOptions = {
//   showLogicTab: true,
//   isAutoSave: true,
//   haveCommercialLicense: true
// };


@Component({
  selector: 'app-survey-creator',
  templateUrl: './survey-creator.component.html',
  styleUrls: ['./survey-creator.component.css']
})
export class SurveyCreatorComponent implements OnInit{


  @ViewChild('svc-json-editor-tab__content-area ng-untouched ng-pristine ng-valid') txtConfigFile: ElementRef;
  constructor(private route: ActivatedRoute ) {
  }

  createdEvaluationId: string;
  createdEvaluationTitle: string;
  uploadedLogo: string;
  createdEvaluationFacilities: any;
  updatedEvaluationFacilities: any;

  updatedEvaluationId: string | null;
  updatedEvaluationTitle: string;
  updatedEvaluation: string;
  updatedEvaluationSurvey: string;
  tokensGenerated: Array<any>;

  evaluationId: string;



  surveyCreatorModel: SurveyCreatorModel;
  ngOnInit() {

    this.getEvaluationForUpdateId()
    // const creator = new SurveyCreatorModel(creatorOptions);
    // creator.text = this.updatedEvaluationSurvey;
    // creator["setRootElement"] = () => {};
    // creator["unsubscribeRootElement"] = () => {};
    // this.surveyCreatorModel = creator as SurveyCreatorModel;
  }

  getEvaluationForUpdateId(){
    this.updatedEvaluationId = this.route.snapshot.paramMap.get('id');
  }
  receiveTokensGenerated(event: Array<any>){
    this.tokensGenerated = event;
  }

  receiveCreatedEvaluationId(event: string){
    this.createdEvaluationId = event;
  }

  receiveCreatedEvaluationTitle(event: string){
    this.createdEvaluationTitle = event;
  }

  receiveUpdatedEvaluationTitle(event: string){
    this.updatedEvaluationTitle = event;
  }

  receiveUploadedLogo(event: string){
    this.uploadedLogo = event;
  }

  receiveUpdatedEvaluationSurvey(event: string){
    return this.updatedEvaluationSurvey = event;
  }

  receiveUpdatedEvaluation(event: string){
    return this.updatedEvaluation = event;
  }

  receiveEvaluationId(event: string){
    return this.evaluationId = event;
  }

  receiveCreatedEvaluationFacilities(event: any) {
    return this.createdEvaluationFacilities = event;
  }

  receiveUpdatedEvaluationFacilities(event: any) {
    return this.updatedEvaluationFacilities = event;
  }

}

