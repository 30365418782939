<div class="container">
    <img *ngIf="currentEvaluation" alt="logo" [src]="this.currentEvaluation?.logo" class="evaluationLogo" loading="lazy"/>
    <div *ngIf="!currentEvaluation" class="evaluationLogo"></div>
    <div
            class="body"
            *ngIf="this.currentLanguage === 'de' && this.faq.de"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.faq.de || '<p></p>')"
    >
    </div>
    <div
            class="body"
            *ngIf="this.currentLanguage === 'en' && this.faq.en"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.faq.en || '<p></p>')"
    >
    </div>
    <div
            class="body"
            *ngIf="this.currentLanguage === 'ua' && this.faq.ua"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.faq.ua || '<p></p>')"
    >
    </div>
    <div
            class="body"
            *ngIf="this.currentLanguage === 'ar' && this.faq.ar"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.faq.ar || '<p></p>')"
    >
    </div>
    <div
            class="body"
            *ngIf="this.currentLanguage === 'ru' && this.faq.ru"
            [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(this.faq.ru || '<p></p>')"
    >
    </div>
</div>
