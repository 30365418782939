import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Evaluation} from "../../model/Evaluation";
import {EvaluationService} from "../../services/evaluation.service";
import {ContextService} from "../../services/context.service";

@Component({
  selector: 'app-evaluation-list',
  templateUrl: './evaluation-list.component.html',
})
export class EvaluationListComponent implements OnInit{

  @Output() ChosenEvaluation = new EventEmitter<Evaluation>

  chosenEvaluation: Evaluation
  evaluations: Evaluation[];

  onSelect(){
      this.context.activeEvaluation = this.chosenEvaluation
      this.ChosenEvaluation.emit(this.chosenEvaluation)
  }

  constructor(private evaluationService: EvaluationService,
              private context: ContextService) { }

  ngOnInit(): void {
    // this.evaluationService.getAllExistingEvaluations().subscribe(data => {
    //       this.evaluations = data;
    // });

      this.evaluationService.getEvaluationsByType("PUBLISHED").subscribe( response =>{
          this.evaluations = response;
      })
  }

}
