import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public static LOGINPAGE: string = "";
  public static FAQPAGE: string = "faq";
  public static SURVEYPAGE: string = "survey-page";
  public static FINISH_SURVEYPAGE: string = "finish-survey-page";
  public static ADMINPAGE: string = "admin-page";
  public static RESULTPAGE: string = "result-page";
  public static RESULT: string = "result";
  public static CREATORPAGE: string = "creator-page";
  public static EDITORPAGE: string = "update";
  public static ORGANIZATIONS: string = "organizations";
  public static ORGANIZATION: string = "organization";
  public static ORGANIZATIONCREATOR: string = "organization-creator";
  public static FACILITY: string = "facility";
  public static FACILITYCREATOR = "facility-creator";
  public static TOKENS = "tokens";
  public static TERMSANDCONDITIONS = "termsandconditions";
  public static PRIVACYPOLICY = "privacypolicy"

  constructor() { }

}
