import {Component, OnInit} from '@angular/core';
import {NavigationService} from "../services/navigation.service";
import {Router} from "@angular/router";
import {EvaluationService} from "../services/evaluation.service";
import {Evaluation} from "../model/Evaluation";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {WarningModalDialogComponent} from "../warning-modal-dialog/warning-modal-dialog.component";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})

export class AdminPageComponent implements OnInit {

  evaluations: any
  editButtonDisabled = false
  deleteButtonDisabled = false
  seeResultButtonDisabled = false
  deactivatedButtonDisabled = false
  reactivatedButtonDisabled = false

  searchTerm = ''
  filteredEvaluations: Evaluation[]

  selectedState = 'All';

  constructor(private evaluationService: EvaluationService,
              private router: Router,
              public translate: TranslateService,
              public matDialog: MatDialog){}

  ngOnInit() {
    this.getEvaluations()
  }

  getEvaluations(){
    this.evaluationService.getAllExistingEvaluations().subscribe(result => {
        this.evaluations = result.sort((a:any, b:any) => Date.parse(b.startdate) - Date.parse(a.startdate))
        this.filteredEvaluations = this.evaluations?.filter((item) => item?.state !== 'DELETED')
    })
  }

  onShowResults(evaluationId: number){
    this.router.navigate([NavigationService.RESULT + '/' + evaluationId?.toString()]).then()
  }


  onEdit(evaluation: Evaluation){
    this.router.navigate([NavigationService.CREATORPAGE + '/' + evaluation.id?.toString()]).then()
  }

  disableEditButton(evaluation: Evaluation): boolean{
    this.editButtonDisabled = evaluation.state?.toString() == "DELETED" || evaluation.state?.toString() == "PUBLISHED" || evaluation.state?.toString() == "DEACTIVATED";
    return this.editButtonDisabled
  }

  disableDeleteButton(evaluation: Evaluation): boolean{
    this.deleteButtonDisabled = evaluation.state?.toString() == "DELETED";
    return this.deleteButtonDisabled
  }

  disableSeeResultButton(evaluation: Evaluation): boolean{
    this.seeResultButtonDisabled = evaluation.state?.toString() == "CREATED";
    return this.seeResultButtonDisabled
  }

  disableDeactivationButton(evaluation: Evaluation){
    this.deactivatedButtonDisabled = evaluation.state?.toString() == "DELETED" || evaluation.state?.toString() == 'DEACTIVATED' || evaluation.state?.toString() == 'CREATED';
    return this.deactivatedButtonDisabled
  }

  disableReactivationButton(evaluation: Evaluation) {
    this.reactivatedButtonDisabled = evaluation.state?.toString() == "DELETED" || evaluation.state?.toString() == 'PUBLISHED' || evaluation.state?.toString() == 'CREATED' ;
    return this.reactivatedButtonDisabled
  }

  search(searchTerm: string) {
    this.searchTerm = searchTerm.toLowerCase();
  }

  handleSelectState(state: string){
    if(state != 'All'){
      this.filteredEvaluations = this.evaluations?.filter((item) => item.state == state.toUpperCase())
    } else {
      this.filteredEvaluations = this.evaluations?.filter((item) => item?.state !== 'DELETED')
    }
  }

  openModal(message: string, warning: string, buttonName: string,  evaluationId: string, type: string) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: this.translate.instant(message),
      warning: this.translate.instant(warning),
      buttonName: this.translate.instant(buttonName),
      onModalClick: ()=>this.changeEvaluationState(evaluationId, type)
    }
    const modalDialog = this.matDialog.open(WarningModalDialogComponent, dialogConfig);
  }

  changeEvaluationState(evaluationId: string, type: string){
    this.evaluationService.changeEvaluationState(evaluationId, type).subscribe(result => {
      this.filteredEvaluations = this.filteredEvaluations.map((element) => element.id === Number(evaluationId) ? result : element)
    })
  }

  handleTokensPageNavigate(evaluationId: string) {
    this.router.navigate([NavigationService.TOKENS + '/' + evaluationId]).then()
  }

  protected readonly NavigationService = NavigationService;
  protected readonly String = String;
}
