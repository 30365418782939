import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountryService} from "../../../services/country.service";



@Component({
  selector: 'app-geo-chooser',
  templateUrl: './geo-chooser.component.html',
  styleUrls: ['./geo-chooser.component.css']
})
export class GeoChooserComponent implements OnInit{

  @Output() CommunityId  =new EventEmitter<number>

  countryModel;
  country;
  state;
  county;
  community;

  constructor(private countryService: CountryService){}

  getCountryModel(){
    this.countryService.getCountryModel().subscribe(result => {
      this.countryModel = result
    })
  }

  ngOnInit(): void {
    this.getCountryModel()
  }

  handleCommunitySelect() {
    if(this.community.id){
      this.CommunityId.emit(this.community.id)
    }
  }
}
