import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FacilitiesService} from "../../services/facilities.service";
import {ActivatedRoute} from "@angular/router";
import { Location } from '@angular/common';

@Component({
  selector: 'app-facility-creator',
  templateUrl: './facility-creator.component.html',
  styleUrls: ['./facility-creator.component.css']
})
export class FacilityCreatorComponent implements OnInit {

  facilityForm: FormGroup
  facilityId: string | null
  facilityForUpdate: any
  communityId: number;

  constructor(private facilitiesService: FacilitiesService,
              private route: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit() {
    this.facilityForm = new FormGroup({
      name: new FormControl('', Validators.required),
      // type: new FormControl('', Validators.required),
      shortname:new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{5}')]),
      city: new FormControl('', Validators.required),
      contactpersonfirstname: new FormControl('', Validators.required),
      contactpersonfamilyname: new FormControl('', Validators.required),
      phonenumber: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required])
    })
    this.getFacilityId()
    this.getFacilityForUpdate()
  }

  getFacilityId(){
    this.facilityId = this.route.snapshot.paramMap.get('facilityId')
  }

  getFacilityForUpdate(){
    if(this.facilityId){
      this.facilitiesService.getFacilityById(this.facilityId).subscribe(response => {
        this.facilityForUpdate = response;
        this.communityId = response?.community_id;
        console.log('This facility for Update>>>', this.facilityForUpdate)
        this.facilityForm.patchValue({name: response?.name,
          type: response?.type,
          shortname: response?.shortname,
          address: response?.address,
          postcode: response?.postcode,
          city: response?.city,
          contactpersonfirstname: response?.contactpersonfirstname,
          contactpersonfamilyname: response?.contactpersonfamilyname,
          phonenumber: response?.phonenumber,
          email: response?.email
        })
      })
    }
  }

  createNewFacility(): string{
    return {
      ...this.facilityForm.value,
      organization:{
        id: this.route.snapshot.paramMap.get('organizationId')
      },
      community_id: this?.communityId
    }
  }

  handleFacilitySubmit(){
    if(this.facilityId){
      console.log("this.form value>>", this.createNewFacility())
      this.facilitiesService.updateFacilityById(this.facilityId, this.createNewFacility()).subscribe(() => {
        this.location.back()
      })
    }
    else{
      this.facilitiesService.createFacility(this.createNewFacility()).subscribe(  () => {
        this.location.back()
      })
    }
  }

  receiveCommunityId(event: number){
    this.communityId = event;
  }
}
