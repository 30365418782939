import {Component, EventEmitter,  OnDestroy, OnInit, Output} from '@angular/core';
import {EvaluationService} from "../../../services/evaluation.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Evaluation} from "../../../model/Evaluation";
import {calculateDateDifference} from "../../../common/utils/calculateDateDifference";
import { Editor, Toolbar } from 'ngx-editor';
import {FaqPreviewDialogComponent} from "../faq-preview-dialog/faq-preview-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";





@Component({
    selector: 'app-evaluation-creator',
    templateUrl: './evaluation-creator.component.html',
    styleUrls: ['./evaluation-creator.component.css'],

})

export class EvaluationCreatorComponent implements OnInit, OnDestroy {
    @Output() CreatedEvaluationId = new EventEmitter<string>
    @Output() UploadedLogo = new EventEmitter<string>
    @Output() CreatedEvaluationTitle = new EventEmitter<string>
    @Output() UpdatedEvaluationSurvey = new EventEmitter<string>
    @Output() CreatedEvaluationFacilities = new EventEmitter<any>

    createdEvaluationID: string;
    createdEvaluationTitle: string;
    base64textString: string;

    tokensDictionary: Record<string, string> | null = null;
    facilitiesToDisplay: string[] = [];

    myForm: FormGroup;
    updatingEvaluation: Evaluation;

    public selectedTab: BehaviorSubject<string> = new BehaviorSubject<string>('de');
    minimalDateStart = calculateDateDifference(new Date(), -1);
    minimalDateEnd = calculateDateDifference(new Date(), 2);

    constructor(private evaluationService: EvaluationService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                ) {
    }

    editorDe: Editor;
    editorEn: Editor;
    editorRu: Editor;
    editorUa: Editor;
    editorAr: Editor;

    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    tokenTypes = [
        {code: 'CUSTOMER', label: 'Customer'},
        {code: 'EMPLOYEE', label: 'Employee'},
        {code: 'TEAM', label: 'Team'},
        {code: 'MANAGEMENT', label: 'Management'},
    ];

    //let facilitiesForSelect facilities for select variable
    //subscribe myForm.facilities value length change
    // make algorythm to filter facilitiesForSelect; put there all facilitites from myForm.get('organization')?.value instead of used;

    ngOnDestroy(): void {
        this.editorDe.destroy();
        this.editorEn.destroy();
        this.editorRu.destroy();
        this.editorUa.destroy();
        this.editorAr.destroy();
    }

    ngOnInit() {
        this.editorDe = new Editor();
        this.editorEn = new Editor();
        this.editorRu = new Editor();
        this.editorUa = new Editor();
        this.editorAr = new Editor();
        //@TODO connect date validators (function is ready, common/utils/dateValidators)
        this.myForm = this.fb.group({
            name: ["", Validators.required],
            organization: [null, Validators.required],
            startdate: ["", Validators.required],
            enddate: ["", Validators.required],
            customization: ["assets/styles.css", Validators.required],
            logo: [null, []],
            evaluationDescription: ['', []],
            creationdate: [null, []],
            updatedate: [null, []],
            faq: this.fb.group({ // make a nested group
                ru: '',
                de: '',
                en: '',
                uk: '',
                ar: '',
            }),
            facilities: this.fb.array([
                this.saveTokensForFacility()
            ]),
            customTokenTypeTitles: this.fb.array([])
        });
        this.myForm.get('startdate')?.valueChanges.subscribe((newStartDate) => {
            this.minimalDateEnd = calculateDateDifference(new Date(newStartDate), 2);
        });
    }



    saveTokensForFacility(): FormGroup {
        return this.fb.group({
            facility_id: ['', []],
            generatedEmployeeTokens: [0, []],
            generatedCustomerTokens: [0, []],
            generatedTeamTokens: [0, []],
            generatedManagementTokens: [0, []],
            facility_name: ['', []],
        })
    }

    addTokensForFacility() {
        if(this.facilities.length > 0) {

            let check = this.facilities.at(this.facilities.length - 1).value;
            if(check.facility_name && (check.generatedEmployeeTokens || check.generatedCustomerTokens || check.generatedTeamTokens || check.generatedManagementTokens )) {
                let facilityFromList = this.myForm.value?.organization?.facilities.filter(item => check.facility_name === item.name)
                let facilityId = facilityFromList[0].id
                check.facility_id = facilityId

                this.facilities.push(this.saveTokensForFacility());
            }
        }
    }



    get facilities() {
        return this.myForm.get('facilities') as FormArray;
    }


    createNewEvaluation(): string {
        const facilitiesToSend = this.myForm.get('facilities')?.value?.map((facility) => ({
            ...facility,
            facility_id: this.myForm.get('organization')?.value?.facilities?.find((el) => el?.name === facility?.facility_name)?.id
        }))

        const newEvaluation = {
            ...this.myForm.value,
            organization: {
                id: this.myForm.value?.organization?.id
            },
            facilities: facilitiesToSend?.filter((el) => !el?.facility_id)
        };

        newEvaluation.updatedate = Date
        if (newEvaluation.name !== null)
            this.createdEvaluationTitle = newEvaluation.name
        if (newEvaluation.logo !== null)
            newEvaluation.logo = this.base64textString
        if (newEvaluation.customization === null)
            newEvaluation.customization = "assets/styles/default.css"
        newEvaluation.creationdate = new Date()
        newEvaluation.state = "CREATED";
        newEvaluation.faq = Object.keys(newEvaluation.faq).map((key) => ({
            lang: key,
            description: newEvaluation.faq[key]
        }))
        return newEvaluation
    }

    onLogoLoad(evt: any) {
        const file = evt.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleReaderLoaded(e: any) {
        this.base64textString = ('data:image/png;base64,' + btoa(e.target.result));
    }

    onTabChanged(event: any) {
        switch (event.index) {
            case 0:
                this.selectedTab.next('de');
                break;
            case 1:
                this.selectedTab.next('en');
                break;
            case 2:
                this.selectedTab.next('uk');
                break;
            case 3:
                this.selectedTab.next('ru');
                break;
            case 4:
                this.selectedTab.next('ar');
                break;
        }
    }

    openFaqPreviewDialog() {
        const dialogRef = this.dialog.open(FaqPreviewDialogComponent,{
            data:{
                message: this.myForm.value?.faq?.[this.selectedTab.value],
                buttonText: {
                    cancel: 'Done'
                }
            },
        });
    }

    onEvaluationDataSubmit() {
        if (!this.updatingEvaluation) {
            this.evaluationService.createNewEvaluation(this.createNewEvaluation())
                .subscribe(response => {
                    this.tokensDictionary = response.tokenDictionary as any;
                    this.createdEvaluationID = JSON.stringify(response.id)
                    this.CreatedEvaluationId.emit(this.createdEvaluationID)
                    this.CreatedEvaluationTitle.emit(this.createdEvaluationTitle)
                    this.UploadedLogo.emit(this.base64textString)
                    this.CreatedEvaluationFacilities.emit(this.myForm.value.facilities)
                })
        }
    }

    get customTokenTypeTitles(){
        return this.myForm.get('customTokenTypeTitles') as FormArray;
    }

    addCustomTitle(){
        const usedTokenTypes = this.myForm.value?.customTokenTypeTitles?.map((el) => el.tokenType);
        const availableTokenTypes = this.tokenTypes
            ?.filter((el) => !usedTokenTypes?.some((usedToken) => usedToken === el?.code))
        console.log("availableTokenTypes", availableTokenTypes);
        if (availableTokenTypes?.length) {
            this.customTokenTypeTitles.push(
                this.fb.group({
                    tokenType: [availableTokenTypes?.[0]?.code, []],
                    customTitle: ["", Validators.required]
                })
            )
        }
    }

    removeCustomTitle(index: number){
        this.customTokenTypeTitles.removeAt(index)
    }

    deleteFacility(i: number) {
        this.facilities.removeAt(i)


    }

    protected readonly JSON = JSON;
}

