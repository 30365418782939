import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})

export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  error(message: string) {
    return this.snackBar.open(message, undefined, {panelClass: ['snackbar-error'], duration: 3000, horizontalPosition: 'center', verticalPosition: 'top'});
  }

  success(message: string) {
    return this.snackBar.open(message, undefined, {panelClass: ['snackbar-success'], duration: 3000, horizontalPosition: 'center', verticalPosition: 'top'});
  }

  info(message: string) {
    return this.snackBar.open(message, undefined, {panelClass: ['snackbar-info'], duration: 3000, horizontalPosition: 'center', verticalPosition: 'top'});
  }
}
