<div class="container">
    <p>
        <b>IMPRESSUM</b><br>
        <br>
        <b>Anbieterkennzeichnung gemäß den gesetzlichen Bestimmungen</b><br>
        GEB „Gemeinnützige Gesellschaft für elementare Bildung mbH“<br>
        Institut für Qualifizierung, Entwicklung, Praxis und Begleitung<br>
        Agnetenstraße 14<br>
        39106 Magdeburg<br>
        Tel: 0391-79293-371<br>
        Funk: 0171-971 99 88<br>
        Email: info@gesellschaft-elementare-bildung.de<br>
        <br>
        Geschäftsführer: Adrian Einecke <br>
        <br>
        <b>Registergericht</b><br>
        Amtsgericht Stendal<br>
        HRB: 26040<br>
        <br>
        <b>Verantwortlich für den Web-Auftritt</b><br>
        GEB „Gemeinnützige Gesellschaft für elementare Bildung mbH“<br>
        Institut für Qualifizierung, Entwicklung, Praxis und Begleitung<br>
        Agnetenstraße 14<br>
        39106 Magdeburg<br>
        Tel: 0391-79293-371<br>
        Funk: 0171-971 99 88<br>
        Email: info@gesellschaft-elementare-bildung.de<br>
        <br>
        <b>Datenschutzbeauftragte:</b><br>
        Stiftung Evangelische Jugendhilfe St. Johannis Bernburg<br>
        Dr.-John-Rittmeister-Straße 6<br>
        06406 Bernburg (Saale)<br>
        E-Mail: datenschutz@stejh.de<br>
        <br>
        <b>Copyright-Hinweis</b><br>
        Alle auf unseren Seiten verwendeten Bilder, Fotos, Logos, Texte etc. unterliegen dem Copyright der Stiftung Evangelische Jugendhilfe St. Johannis Bernburg bzw. von Dritten. Sie dürfen nicht bzw. nur nach Rücksprache mit der Redaktion weiter verwendet werden.
        Rechtlicher Hinweis zur Haftung
        Die Inhalte externer Links werden von uns sorgfältig geprüft. Wir übernehmen dennoch keine Haftung für die Inhalte der mit oder von unserer Website verlinkten externen Seiten. Für die Inhalte dieser Seiten sind ausschließlich deren Anbieter verantwortlich.
        Copyright-Hinweis
        Alle auf unseren Seiten verwendeten Bilder, Fotos, Logos, Texte etc. unterliegen dem Copyright der Stiftung Evangelische Jugendhilfe St. Johannis Bernburg bzw. von Dritten. Sie dürfen nicht bzw. nur nach Rücksprache mit der Redaktion weiter verwendet werden.
        <br><br>
        <b>Rechtlicher Hinweis zur Haftung</b><br>
        Die Inhalte externer Links werden von uns sorgfältig geprüft. Wir übernehmen dennoch keine Haftung für die Inhalte der mit oder von unserer Website verlinkten externen Seiten. Für die Inhalte dieser Seiten sind ausschließlich deren Anbieter verantwortlich.
        <br><br>
    </p>
</div>
