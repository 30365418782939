<!-- <button class="register1" type="button" (click)="this.authenticationService.logOut()" [routerLink]="['/', NavigationService.LOGINPAGEQR]"> LogOut</button>
-->

<!--<head>-->
<!--    <script src="https://unpkg.com/survey-core/survey.i18n.min.js"></script>-->
<!--</head>-->

<div *ngIf="!complete" class="login-copy">
    <div class="w-layout-hflex flex-block-2">
        <ul role="list" class="list" *ngFor="let page of this.survey?.visiblePages;let i=index">
            <li class="list-item">
                <a class="prim-button-survey w-button" (click)="surveyNavigate(i)">{{page.name || page.description}}</a>
            </li>
        </ul>
    </div>
</div>

<div id="surveyContainer" *ngIf="!complete"></div>