import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

export type Faq = {
    ru: string;
    de: string;
    en: string;
    ua: string;
    ar: string;
}

@Injectable({
    providedIn: 'root',
})
export class FaqService {
    public faq: BehaviorSubject<Faq | null> = new BehaviorSubject<Faq | null>(null);

    constructor() {
    }
}