import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FacilitiesService} from "../../services/facilities.service";

@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.css'],
})

export class FacilityDetailsComponent implements OnInit {

  facilityId: string | null
  facility: any

  constructor(private route: ActivatedRoute,
              private facilitiesService: FacilitiesService,
              private router: Router){
  }
  ngOnInit() {
    this.getFacilityId()
    this.getFacilityById()
  }

  getFacilityId(){
    this.facilityId = this.route.snapshot.paramMap.get('facilityId')
  }

  getFacilityById(){
    if(this.facilityId){
      this.facilitiesService.getFacilityById(this.facilityId).subscribe(response => {
        this.facility = response
      })
    }
  }

  goToFacilityEditing(){
    if(this.facilityId){
      this.router.navigate(['edit'], {relativeTo:this.route})
    }
  }
}
