<mat-card class="col-md-7 card">
    <div class="align_menu">
        <div class="card-header">
            <span class="truncate_name">{{ organization?.name }}</span>
        </div>
    </div>
    <div class="card-body">
        <span>id: {{organization?.id}}</span><br>
    </div>
</mat-card>
