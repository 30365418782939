import {EvaluationStatus} from '../interfaces/EvaluationStatus';
import {Title} from "./Title";

export class Evaluation {
    id: number;
    name: string;
    startdate: Date;
    enddate: Date;
    generatedCustomerTokens: number;
    generatedEmployeeTokens: number;
    status: EvaluationStatus;
    description: string;
    logo: string;
    customization: string;
    creationdate: Date;
    updatedate: Date;
    state: string;
    survey: string;
    organization_id: string;
    evaluationDescription: string;
    faq: Array<Title>;
    facilities: Array<{
        facility_id: number,
        generatedEmployeeTokens: number,
        generatedCustomerTokens: number,
        generatedTeamTokens: number,
        generatedManagementTokens: number,
        facility_name: number,
    }>;
    customTokenTypeTitles?: Array<any>;
    customSurvey: any;
    tokenDictionary?: Record<string, string>;
}

///logo is in evaluations 1 and 3

