import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {ActivatedRoute, Router} from '@angular/router';
import {Model, StylesManager, SurveyNG} from 'survey-angular';
import {AuthenticationService} from "../services/authentication.service";
import {NavigationService} from "../services/navigation.service";
import {Subscription} from "rxjs";
import {ContextService} from "../services/context.service";
import {EvaluationService} from "../services/evaluation.service";
import "survey-core/survey.i18n";
import {CustomizationService} from "../services/customization.service";
import {Faq, FaqService} from "../services/faqService";


StylesManager.applyTheme('modern');

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
})
export class SurveyComponent implements OnInit, OnDestroy {

    survey: Model;

    tempData: any
    defaultData: any
    complete: boolean = false

    private idlSubscription: Subscription;

    constructor(private bnIdle: BnNgIdleService,
                private router: Router,
                public authenticationService: AuthenticationService,
                private contextService: ContextService,
                private evaluationService: EvaluationService,
                private route: ActivatedRoute,
                private customizationService: CustomizationService,
                private faqService: FaqService,
    ) {
    }

    ngOnInit() {
        this.customizationService.chooseColorTheme()
        this.getEvaluationById();
        this.getSurveyAndRender();
        this.checkAndLogoutByInactivity();
        localStorage.setItem('currentEvaluationId', this.getEvaluationId())
    }


    getEvaluationId(): any {
        if (this.contextService.activeEvaluation) {
            return this.contextService.activeEvaluation.id
        } else
            return Number(this.route.snapshot.paramMap.get('id'));
    }


    checkAndLogoutByInactivity() {
        this.idlSubscription = this.bnIdle.startWatching(1000).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                this.saveIntermediateResult()
                this.authenticationService.logoutByInactivity();
                this.router.navigate([NavigationService.LOGINPAGE]).then();
            }
        });
    }

    getEvaluationById() {
        this.evaluationService.getEvaluationById(this.getEvaluationId()).subscribe(evaluation => {
            this.faqService.faq.next(evaluation.faq?.reduce((acc, item) => {
                return {
                    ...acc,
                    [item?.lang]: item?.description
                }
            }, {}) as Faq)

            this.contextService.activeEvaluation = evaluation
            if (this.contextService.activeEvaluation.customization)
                this.customizationService.loadStyle(this.contextService.activeEvaluation.customization)
            else
                this.customizationService.loadStyle("/assets/styles-default.css")
            this.customizationService.chooseColorTheme()
        })
    }

    getSurveyAndRender() {
        this.evaluationService.readSurveyOfEvaluation(this.getEvaluationId()).subscribe(data => {
            this.survey = new Model(data)
            this.defaultData = this.survey.data
            this.survey.locale = localStorage.getItem('lang') || "de";
            this.survey.showCompletedPage = false;

            SurveyNG.render('surveyContainer', {model: this.survey});
            this.saveFinalResult()
            this.saveIntermediateResult()
            this.getIntermediateResult()
        }, error => {
            alert(`get Survey and Render error:` + error);
        })
    }

    saveFinalResult() {
        this.survey.onComplete.add((sender) => {
            this.survey.mode = "display";
            this.survey.clear(false);
            this.evaluationService.saveSurveyOfEvaluationFinalResult(this.getEvaluationId(), sender.data)
                .subscribe(
                    () => {
                        this.authenticationService.logOut();
                        this.router.navigate(['/', NavigationService.FINISH_SURVEYPAGE])
                        // this.authenticationService.logOut()
                        // this.router.navigate(['/', 'login-page'])
                    },
                    error => {
                        alert(`save final result error:` + error)
                    }
                )
        });
    }

    saveIntermediateResult(): boolean {
        this.survey.onValueChanged.add((sender) => {
            this.evaluationService.saveSurveyOfEvaluationIntermediateResult(this.getEvaluationId(), sender.data)
                .subscribe(
                    () => {
                    },
                    error => {
                        alert(`save intermediate result on value changed error:` + error)
                    }
                )
            return true
        });
        this.survey.onCurrentPageChanged.add((sender) => {
            this.evaluationService.saveSurveyOfEvaluationIntermediateResult(this.getEvaluationId(), sender.data)
                .subscribe(
                    () => {
                    },
                    error => {
                        alert(`save intermediate result on current page changed error:` + error)
                    }
                )
            return true
        });
        return false
    }

    getIntermediateResult(): any {
        if (localStorage.getItem("token")) {
            this.evaluationService.getIntermediateResult(this.getEvaluationId()).subscribe(data => {
                this.tempData = data
                if(data == null){
                    this.evaluationService.saveSurveyOfEvaluationIntermediateResult(this.getEvaluationId(), this.survey.data).subscribe( result => {
                        //do something
                    })
                }
                this.customizationService.chooseColorTheme()
                if (this.tempData) {
                    this.survey.data = JSON.parse(this.tempData);
                }

            }, error => {
                alert(`get intermediate result error:` + error);
            });
        }
    }

    surveyNavigate(pageIndex: any) {
        this.survey.currentPageNo = pageIndex;
    }

    ngOnDestroy() {
        if (this.idlSubscription) this.idlSubscription.unsubscribe();
        this.faqService.faq.next(null);
        localStorage.removeItem('currentEvaluationId')
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        localStorage.removeItem('currentEvaluationId');
    }

    protected readonly NavigationService = NavigationService;
}
