import {Component, Input, OnInit} from '@angular/core';
import {EvaluationService} from "../../../../services/evaluation.service";
import {BehaviorSubject} from "rxjs";
import {Evaluation} from "../../../../model/Evaluation";
import {OrganizationService} from "../../../../services/organization.service";
import {Organization} from "../../../../model/Organization";

@Component({
    selector: 'result-common-info',
    templateUrl: 'result-common-info.component.html',
    styleUrls: ['result-common-info.component.css'],
})
export class ResultCommonInfoComponent implements OnInit {
    constructor(
        private evaluationService: EvaluationService,
        private organizationService: OrganizationService,
    ) {}

    @Input() evaluationId: string;

    currentEvaluation: BehaviorSubject<Evaluation | null> = new BehaviorSubject<Evaluation | null>(null);

    evaluationSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    currentOrganization: BehaviorSubject<Organization | null> = new BehaviorSubject<Organization | null>(null);

    tokensCounted: Record<string, number> = {}

    ngOnInit() {
        this.tokensCounted = {
            generatedCustomerTokens: 0,
            generatedEmployeeTokens: 0,
            generatedManagementTokens: 0,
            generatedTeamTokens: 0,
        };

        this.evaluationService.getEvaluationById(this.evaluationId)
            .subscribe((evaluation) => {
                this.currentEvaluation.next(evaluation);
                this.tokensCounted = evaluation?.facilities?.reduce((acc, facility) => {
                    acc['generatedCustomerTokens'] += facility.generatedCustomerTokens;
                    acc['generatedEmployeeTokens'] += facility.generatedEmployeeTokens;
                    acc['generatedManagementTokens'] += facility.generatedManagementTokens;
                    acc['generatedTeamTokens'] += facility.generatedTeamTokens;
                    return acc;
                }, this.tokensCounted)
            })
            .add(() => {
                if (this.currentEvaluation.value?.organization_id) {
                    this.organizationService.getOrganizationById(this.currentEvaluation.value?.organization_id)
                        .subscribe((organization) => {
                            this.currentOrganization.next(organization);
                        })
                }
            })

        this.evaluationService.getEvaluationSummaryById(this.evaluationId).subscribe((summary) => {
            this.evaluationSummary.next(summary);
        })
    }
}