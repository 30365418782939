<app-sidebar>
    <div class="container">
        <h1>{{'organizationsDashboard' | translate}}</h1>
        <app-search-bar (SearchTerm)="receiveSearchTerm($event)" ></app-search-bar>
        <div class="button-container">
            <button id="create-organization-button" mat-raised-button color="primary" (click)="goToOrganizationCreation()">{{'createOrganization' | translate}}</button>
        </div>
        <div class="card-container card-grid">
            <ng-container *ngFor="let organization of allOrganizations | searchFilter: searchTerm; index as i">
                <mat-card class="col-md-7 card">
                    <div class="align_menu">
                        <div class="card-header">
                            <span class="truncate_name">{{ organization.name }}</span>
                        </div>
                        <div class="dropdown">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="goToOrganizationDetails(organization.id)">
                                    <span>{{'seeDetails' | translate}}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="card-body">
                        <strong>{{'address' | translate}}: </strong>
                        <span>{{organization.address }}, </span>
                        <span>{{organization.postcode}} {{organization.city}}</span><br>
                        <strong>{{'contactPerson' | translate}}: </strong>
                        <span>{{organization.contactpersonfirstname}} {{organization.contactpersonfamilyname}}</span><br>
                    </div>
                </mat-card>
            </ng-container>
        </div>
    </div>
</app-sidebar>