<!--<div class="popup">-->
<!--    <h4 class="evaluation_heading">{{ 'pleaseEditEvaluation' | translate}}</h4>-->
<!--    <div class="w-layout-hflex flex-block">-->
<!--        <form (ngSubmit)="onEvaluationDataSubmit()"-->
<!--              [formGroup]="myForm" id="loginForm" name="wf-form-Email-Form" data-name="Email Form" method="get"-->
<!--              class="form evaluation_form">-->
<!--            <div>-->
<!--                <label class="formElement">-->
<!--                    <span class="formElementLabel">{{ 'evaluationName' | translate }}</span>-->
<!--                    <input formControlName="name" placeholder="{{ 'pleaseWriteTheNameOfEvaluation' | translate }}"-->
<!--                           class="textfield w-input" maxlength="256" name="field" data-name="Field" required="true">-->
<!--                </label>-->

<!--                <div class="error"-->
<!--                     *ngIf="myForm.get('name')?.invalid && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">-->
<!--                    {{ 'pleaseChoseName' | translate }}-->
<!--                </div>-->
<!--            </div>-->
<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'organizationId' | translate}}</span>-->
<!--                <app-organization-list-->
<!--                        [defaultOrganization]="this.myForm.value?.organization"-->
<!--                        [formGroup]="myForm"-->
<!--                        [formControl]="$any(this.myForm).controls['organization']"-->
<!--                ></app-organization-list>-->
<!--            </label>-->
<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'startDate' | translate}}</span>-->
<!--                <input-->
<!--                        [min]="this.minimalDateStart"-->
<!--                        formControlName="startdate"-->
<!--                        placeholder="{{ 'pleaseSelectStartDate' | translate }}"-->
<!--                        type="date"-->
<!--                        class="textfield w-input"-->
<!--                        maxlength="256"-->
<!--                        name="field"-->
<!--                        data-name="Field"-->
<!--                        required="true"-->
<!--                />-->
<!--            </label>-->

<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'endDate' | translate }}</span>-->
<!--                <input-->
<!--                        [min]="this.minimalDateEnd"-->
<!--                        formControlName="enddate"-->
<!--                        placeholder="{{ 'pleaseSelectEndDate' | translate}}"-->
<!--                        type="date"-->
<!--                        class="textfield w-input"-->
<!--                        maxlength="256"-->
<!--                        name="field"-->
<!--                        data-name="Field"-->
<!--                        required="true"-->
<!--                />-->
<!--            </label>-->

<!--            <div class="select_dropdown">-->
<!--                <label for="customization" class="formElementLabel">{{ 'choseColor' | translate }}</label>-->
<!--                <div class="entitySelectWrapper">-->
<!--                    <select class="entitySelect" id="customization" formControlName="customization">-->
<!--                        <option value="assets/styles.css">{{ 'blue' | translate }}</option>-->
<!--                        <option value="assets/styles-default.css">{{ 'green' | translate }}</option>-->
<!--                        <option value="assets/styles-test2.css">{{ 'orange' | translate }}</option>-->
<!--                        <option value="assets/styles-test.css">{{ 'pink' | translate }}</option>-->
<!--                        <option value="assets/styles-test3.css">{{ 'red' | translate }}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--            </div>-->

<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'numberEmployees' | translate }}</span>-->
<!--                <input-->
<!--                        type="number"-->
<!--                        formControlName="generatedEmployeeTokens" required="false"-->
<!--                        placeholder="{{ 'pleaseWriteNumberEmployees' | translate }}"-->
<!--                        class="textfield w-input"-->
<!--                        maxlength="256"-->
<!--                        name="field" data-name="Field">-->
<!--            </label>-->


<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'numberClients' | translate }}</span>-->
<!--                <input formControlName="generatedCustomerTokens" required="false"-->
<!--                       type="number"-->
<!--                       min="0"-->
<!--                       placeholder="{{ 'pleaseWriteNumberClients' | translate }}" class="textfield w-input"-->
<!--                       maxlength="256"-->
<!--                       name="field" data-name="Field">-->
<!--            </label>-->

<!--            <label class="formElement">-->
<!--                <span class="formElementLabel">{{ 'description' | translate }}</span>-->
<!--                <textarea matInput-->
<!--                          formControlName="evaluationDescription"-->
<!--                          cdkTextareaAutosize-->
<!--                          #autosize="cdkTextareaAutosize"-->
<!--                          cdkAutosizeMinRows="3"-->
<!--                          class="textfield w-input"-->
<!--                          cdkAutosizeMaxRows="5"></textarea>-->
<!--            </label>-->

<!--            <div class="upload_file">-->
<!--                <span *ngIf="this.updatingEvaluation?.logo" class="formElementLabel formElement">Logo</span>-->
<!--                <div *ngIf="this.updatingEvaluation?.logo" class="logo_container">-->


<!--                    <img [src]="logoFromServer" alt="logo" width="100">-->

<!--                    <button class="delete_logo_button" type="button" (click)="onLogoDelete($event)"> <mat-icon class="icon-display" >close</mat-icon></button>-->
<!--                </div>-->
<!--                <label *ngIf="!this.updatingEvaluation?.logo" class="formElement">-->
<!--                    <span class="formElementLabel">{{ 'uploadLogo' | translate}}</span>-->

<!--                    <input  accept=".png" formControlName="logo" type="file" required="false" name="field"-->
<!--                           data-name="Field"-->
<!--                           (change)="onLogoLoad($event)">-->
<!--                </label>-->
<!--            </div>-->

<!--            <span class="required">{{ 'required' | translate }}</span>-->

<!--            <mat-accordion class="example-headers-align" multi>-->
<!--                <mat-expansion-panel>-->
<!--                    <mat-expansion-panel-header class="extensionPanelHeader">-->
<!--                        <mat-panel-title class="formElementLabel">-->
<!--                            FAQ-->
<!--                        </mat-panel-title>-->
<!--                    </mat-expansion-panel-header>-->
<!--                    <mat-tab-group formGroupName="faq" (selectedTabChange)="onTabChanged($event)" >-->
<!--                        <mat-tab label="DE">-->
<!--                            <ngx-editor-menu [editor]="editorDe" [toolbar]="toolbar"> </ngx-editor-menu>-->
<!--                            <ngx-editor [editor]="editorDe" formControlName="de"> </ngx-editor>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab label="EN">-->
<!--                            <ngx-editor-menu [editor]="editorEn" [toolbar]="toolbar"> </ngx-editor-menu>-->
<!--                            <ngx-editor [editor]="editorEn" formControlName="en"> </ngx-editor>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab label="UA">-->
<!--                            <ngx-editor-menu [editor]="editorUa" [toolbar]="toolbar"> </ngx-editor-menu>-->
<!--                            <ngx-editor [editor]="editorUa" formControlName="uk"> </ngx-editor>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab label="RU">-->
<!--                            <ngx-editor-menu [editor]="editorRu" [toolbar]="toolbar"> </ngx-editor-menu>-->
<!--                            <ngx-editor [editor]="editorRu" formControlName="ru"> </ngx-editor>-->
<!--                        </mat-tab>-->
<!--                        <mat-tab label="AR">-->
<!--                            <ngx-editor-menu [editor]="editorAr" [toolbar]="toolbar"> </ngx-editor-menu>-->
<!--                            <ngx-editor [editor]="editorAr" formControlName="ar"> </ngx-editor>-->
<!--                        </mat-tab>-->
<!--                    </mat-tab-group>-->
<!--                    <button-->
<!--                            *ngIf="this.myForm.value?.faq?.[this.selectedTab.value]"-->
<!--                            class="showPreviewButton"-->
<!--                            mat-button-->
<!--                            type="button"-->
<!--                            (click)="openFaqPreviewDialog()"-->
<!--                    >-->
<!--                        {{'showPreview' | translate}}-->
<!--                    </button>-->
<!--                </mat-expansion-panel>-->
<!--            </mat-accordion>-->
<!--            <button type="submit" [class.disabled]="myForm.invalid" [disabled]="(myForm.invalid || this.submitButtonDisabled)" class="prim-button">-->
<!--                {{ 'goToSurveyCreation' | translate }}-->
<!--            </button>-->
<!--        </form>-->
<!--    </div>-->
<!--</div>-->


<div class="container">
    <h4>{{ 'pleaseCreateEvaluation' | translate}}</h4>
    <div class="sub-container">
        <form (ngSubmit)="this.onEvaluationDataSubmit()"
              [formGroup]="myForm" id="loginForm" name="wf-form-Email-Form" data-name="Email Form" method="get">
            <div>
                <label>{{ 'pleaseChoseName' | translate }}</label>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'evaluationName' | translate }}</mat-label>
                    <input formControlName="name" matInput
                           placeholder="{{ 'pleaseWriteTheNameOfEvaluation' | translate }}">
                </mat-form-field>
                <div class="error"
                     *ngIf="myForm.get('name')?.invalid && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">
                    {{ 'pleaseChoseName' | translate }}
                </div>
            </div>
            <!--            <label class="formElement">-->
            <div class="full-width">
                <label>{{ 'organizationId' | translate}}</label>
                <app-organization-list
                        [formGroup]="myForm"
                        [formControl]="$any(this.myForm).controls['organization']"
                ></app-organization-list>
            </div>
            <!--            </label>-->
            <ng-container *ngIf="myForm.get('organization')?.value">
                <div formArrayName="facilities" class="added-facilities">
                    <div *ngFor="let facility of facilities.controls; index as i" [formGroupName]="i"
                         class="added-facilities">
                        <div class="added-facilities full-width">
                            <ng-container>
                                <div class="card-container" *ngIf="i + 1 !== facilities.length">
                                    <div class="text-field one-fifth-width">
                                        <span>{{facility.value.facility_name}}</span></div>
                                    <div class="text-field one-fifth-width">
                                        <span>{{facility.value.generatedEmployeeTokenss}}</span></div>
                                    <div class="text-field one-fifth-width">
                                        <span>{{facility.value.generatedCustomerTokens}}</span></div>
                                    <div class="text-field one-fifth-width">
                                        <span>{{facility.value.generatedTeamTokens}}</span></div>
                                    <div class="text-field one-fifth-width">
                                        <span>{{facility.value.generatedManagementTokens}}</span></div>
                                    <mat-button-toggle>
                                        <mat-icon (click)="deleteFacility(i)">close</mat-icon>
                                    </mat-button-toggle>
                                </div>
                            </ng-container>
                        </div>
                        <div class="inline_elements full-width" *ngIf="i + 1 === facilities.length">
                            <mat-form-field class="one-fifth-width" appearance="outline">
                                <mat-label>Facility</mat-label>
                                <mat-select formControlName="facility_name">
                                    <input formControlName="facility_name" matInput
                                           placeholder="{{'nameOfFacility' | translate}}">
                                    <mat-option *ngFor="let item of $any(myForm.get('organization')?.value).facilities"
                                                [value]="item.name">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="one-fifth-width">
                                <mat-label>Number of clients:</mat-label>
                                <input formControlName="generatedEmployeeTokens" matInput type="number"
                                       placeholder="{{'nameOfFacility' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="one-fifth-width">
                                <mat-label>Number of customers:</mat-label>
                                <input formControlName="generatedCustomerTokens" matInput type="number"
                                       placeholder="{{'nameOfFacility' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="one-fifth-width">
                                <mat-label>Number of team members:</mat-label>
                                <input formControlName="generatedTeamTokens" matInput type="number"
                                       placeholder="{{'nameOfFacility' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="one-fifth-width">
                                <mat-label>Number of parents:</mat-label>
                                <input formControlName="generatedManagementTokens" matInput type="number"
                                       placeholder="{{'nameOfFacility' | translate}}">
                            </mat-form-field>
                            <mat-button-toggle type="submit" [class.disabled]="facilities.invalid"
                                               [disabled]="facilities.invalid" (click)="addTokensForFacility()">
                                <mat-icon>add</mat-icon>
                            </mat-button-toggle>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="inline-fields">
                <div class="half-width">
                    <label>{{ 'choseTheDateRange' | translate }}</label>
                    <mat-form-field appearance="outline" class="mat-form-field">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [formGroup]="myForm" [rangePicker]="picker">
                            <input matStartDate formControlName="startdate" placeholder="Start date">
                            <input matEndDate formControlName="enddate" placeholder="End date">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <!--                    @if (range.controls.start.hasError('matStartDateInvalid')) {-->
                        <!--                    <mat-error>Invalid start date</mat-error>-->
                        <!--                    }-->
                        <!--                    @if (range.controls.end.hasError('matEndDateInvalid')) {-->
                        <!--                    <mat-error>Invalid end date</mat-error>-->
                        <!--                    }-->
                    </mat-form-field>
                </div>
                <div class="half-width">
                    <label>{{ 'choseColor' | translate }}</label>
                    <mat-form-field class="full-width mat-form-field" appearance="outline" >
                        <mat-label>{{ 'choseColor' | translate }}</mat-label>
                        <mat-select formControlName="customization">
                            <input formControlName="customization" matInput>
                            <mat-option value="assets/styles.css">{{ 'blue' | translate }}</mat-option>
                            <mat-option value="assets/styles-default.css">{{ 'green' | translate }}</mat-option>
                            <mat-option value="assets/styles-test2.css">{{ 'orange' | translate }}</mat-option>
                            <mat-option value="assets/styles-test.css">{{ 'pink' | translate }}</mat-option>
                            <mat-option value="assets/styles-test3.css">{{ 'red' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <label>{{ 'description' | translate }}</label>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'description' | translate }}</mat-label>
                <input formControlName="name" matInput>
            </mat-form-field>


            <div class="addButtonBlock">
                <label>Benutzerdefinierte Beschriftungen</label>
                <mat-button-toggle (click)="addCustomTitle()">
                    <mat-icon>add</mat-icon>
                </mat-button-toggle>
            </div>

            <div formArrayName="customTokenTypeTitles">
                <div *ngFor="let customTokenTitle of customTokenTypeTitles.controls; index as index"
                     [formGroupName]="index" class="customTokenTypesBlock">
                    <div class="typeAndTitleBlock">
                        <mat-form-field class="half-width" appearance="outline">
                            <mat-label>Token type</mat-label>
                            <mat-select formControlName="tokenType">
                                <mat-option *ngFor="let item of tokenTypes"
                                            [value]="item.code">{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>


                        <mat-form-field appearance="outline" class="half-width">
                            <mat-label>Benutzerdefinierter Token-Name:</mat-label>
                            <input formControlName="customTitle" matInput>
                        </mat-form-field>
                    </div>

                    <mat-button-toggle
                            (click)="removeCustomTitle(index)">
                        <mat-icon>close</mat-icon>
                    </mat-button-toggle>
                </div>
            </div>


            <div class="upload_file">
                <label class="formElement">
                    <label>{{ 'uploadLogo' | translate}}</label>
                    <input accept=".png" formControlName="logo" type="file" required="false" name="field"
                           data-name="Field"
                           (change)="onLogoLoad($event)">
                </label>
            </div>
            <span class="required">{{ 'required' | translate }}</span>
            <div class="full-width">
                <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel >
                        <mat-expansion-panel-header class="extensionPanelHeader">
                            <mat-panel-title class="formElementLabel">
                                FAQ
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-tab-group formGroupName="faq" (selectedTabChange)="onTabChanged($event)">
                            <mat-tab label="DE">
                                <ngx-editor-menu [editor]="editorDe" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editorDe" formControlName="de"></ngx-editor>
                            </mat-tab>
                            <mat-tab label="EN">
                                <ngx-editor-menu [editor]="editorEn" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editorEn" formControlName="en"></ngx-editor>
                            </mat-tab>
                            <mat-tab label="UA">
                                <ngx-editor-menu [editor]="editorUa" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editorUa" formControlName="uk"></ngx-editor>
                            </mat-tab>
                            <mat-tab label="RU">
                                <ngx-editor-menu [editor]="editorRu" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editorRu" formControlName="ru"></ngx-editor>
                            </mat-tab>
                            <mat-tab label="AR">
                                <ngx-editor-menu [editor]="editorAr" [toolbar]="toolbar"></ngx-editor-menu>
                                <ngx-editor [editor]="editorAr" formControlName="ar"></ngx-editor>
                            </mat-tab>
                        </mat-tab-group>
                        <div class="full-width">
                            <button
                                    *ngIf="this.myForm.value?.faq?.[this.selectedTab.value]"
                                    class="showPreviewButton"
                                    mat-button
                                    type="button"
                                    (click)="openFaqPreviewDialog()"
                            >
                                {{'showPreview' | translate}}
                            </button>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <!--            {{"Name = " }} {{this.myForm.get('name')?.value}}<br>-->
            <!--            {{"Organization = " }} {{this.myForm.get('organization')?.value}}<br>-->
            <!--            {{"Start date = " }} {{this.myForm.get('startdate')?.value}}<br>-->
            <!--            {{"End date = " }} {{this.myForm.get('enddate')?.value}}<br>-->
            <!--            {{"Customization = " }} {{this.myForm.get('customization')?.value}}<br>-->
            <!--            {{"Facilities = " }} {{this.myForm.get('facilities')?.value}}<br>-->
            <div class="full-width">
                <button  type="submit" [class.disabled]="myForm.invalid" [disabled]="myForm.invalid" class="prim-button">
                    {{ 'goToSurveyCreation' | translate }}
                </button>
            </div>

        </form>
        <app-designer *ngIf="this.updatingEvaluationId" [evaluationId]="this.updatingEvaluationId" [tokensDictionary]="this?.updatingEvaluation?.tokenDictionary || null">
        </app-designer>
    </div>
</div>






