import {Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {StylesManager} from "survey-angular";
import {ContextService} from "./context.service";
import {ColorThemesHardcoded} from "../../assets/ColorThemes";

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  constructor(@Inject(DOCUMENT) private document: Document,
              private contextService: ContextService,
              ) { }

  colorThemes = new ColorThemesHardcoded()
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
        'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `${styleName}`; //<--add assets
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.media = 'print';
      style.href = `${styleName}`; //<--add assets
      style.onload = () => { style.media = 'all'; };

      head.appendChild(style);
    }
  }

  chooseColorTheme(){
    if(this.contextService.activeEvaluation !== undefined) {
      if(this.contextService.activeEvaluation.customization === "assets/styles.css")
        this.colorThemes.applyBlueTheme()
      else if (this.contextService.activeEvaluation.customization === "assets/styles-test.css")
        this.colorThemes.applyPinkTheme()
      else if (this.contextService.activeEvaluation.customization === "assets/styles-test2.css")
        this.colorThemes.applyOrangeTheme()
      else if (this.contextService.activeEvaluation.customization === "assets/styles-test3.css")
        this.colorThemes.applyRedTheme()
      else if(this.contextService.activeEvaluation.customization === String(null)){
        this.contextService.activeEvaluation.customization = "assets/styles-default.css"
        this.colorThemes.applyGreenTheme()
      }
      else
        this.colorThemes.applyGreenTheme()
      StylesManager.applyTheme("modern")
    }
  }

  setStyles(){
    if (this.contextService.activeEvaluation.customization) {
      this.loadStyle(this.contextService.activeEvaluation.customization)
    } else {
      this.loadStyle("/assets/styles-default.css")
    }
  }
}

