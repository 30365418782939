<!--<button class="downloadButton"  (click)="onPublishEvaluation()">{{ 'publishEvaluation' | translate }}</button>-->
<button class="downloadButton"  (click)="openModal('Are you sure you want to publish Evaluation?',
'Evaluation will be accessible with tokens again',
'Yes, Publish', '', 'PUBLISHED')">{{ 'publishEvaluation' | translate }}</button>


<div>
    <ng-container *ngFor="let facility of createdEvaluationFacilities">
        {{facility.facility_name}}
        <button class="downloadButton"   (click)="onGenerateTokens(facility.facility_id)">{{ 'generateTokens' | translate }}</button>

    </ng-container>
</div>


<div>
    <ng-container *ngFor="let facility of updatedEvaluationFacilities">
        {{facility.facility_name}}
        <button class="downloadButton"   (click)="onGenerateTokens(facility.facility_id)">{{ 'generateTokens' | translate }}</button>

    </ng-container>
</div>
<!--<button class="downloadButton"  *ngIf="published" (click)="onGenerateTokens()">{{ 'generateTokens' | translate }}</button>-->



