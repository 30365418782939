import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
//component we just created
import {AskForIdDialogComponent} from "../survey/resultDashboard/ask-for-id-dialog/ask-for-id-dialog.component";
import {ResultService} from "./result.service";
import {Dialog} from "@angular/cdk/dialog";

@Injectable()

export class DialogService {

  constructor(private dialog: Dialog,
              private result: ResultService) {}
  evaluationID: string

  open(data: any) {

return this.dialog.open(AskForIdDialogComponent, {
      width: '250px',
      data: data
      });

  }
  evaluationId: string | undefined
  openDialog(){
    const dialogRef = this.dialog.open<string>(AskForIdDialogComponent, {
      width: '250px',
      data: {evaluationId: this.evaluationId}
    });

    dialogRef.closed.subscribe(result => {
      this.evaluationId = result
    })
  }
}