<app-sidebar>
    <app-evaluation-creator
                            *ngIf="!this.updatedEvaluationId"
                            (CreatedEvaluationId)="receiveCreatedEvaluationId($event)"
                            (UploadedLogo)="receiveUploadedLogo($event)"
                            (CreatedEvaluationTitle)="receiveCreatedEvaluationTitle($event)"
                            (UpdatedEvaluationSurvey)="receiveUpdatedEvaluationSurvey($event)"
                            (CreatedEvaluationFacilities)="receiveCreatedEvaluationFacilities($event)"
    ></app-evaluation-creator>
    <app-evaluation-update
                            *ngIf="this.updatedEvaluationId && !this.updatedEvaluation"
                           [updatingEvaluationId] = "this.updatedEvaluationId"
                            (UploadedLogo)="receiveUploadedLogo($event)"
                            (UpdatedEvaluationTitle)="receiveUpdatedEvaluationTitle($event)"
                            (UpdatedEvaluationSurvey)="receiveUpdatedEvaluationSurvey($event)"
                           (UpdatedEvaluation) = "receiveUpdatedEvaluation($event)"
                            (UpdatedEvaluationFacilities)="receiveUpdatedEvaluationFacilities($event)"></app-evaluation-update>
<!--    <app-publish-evaluation-->
<!--                            *ngIf="this.createdEvaluationId && this.createdEvaluationFacilities"-->
<!--                            (TokensGenerated)="receiveTokensGenerated($event)"-->
<!--                            [createdEvaluationFacilities]="this.createdEvaluationFacilities"-->
<!--                            [createdEvaluationId]="createdEvaluationId"></app-publish-evaluation>-->
<!--    <app-publish-evaluation-->
<!--                            *ngIf="this.updatedEvaluationId && this.updatedEvaluationFacilities"-->
<!--                            [updatedEvaluationId] = "this.updatedEvaluationId"-->
<!--                            [updatedEvaluationFacilities]="this.updatedEvaluationFacilities"-->
<!--                            (TokensGenerated)="receiveTokensGenerated($event)"-->
<!--                            (EvaluationId)="receiveEvaluationId($event)"></app-publish-evaluation>-->
<!--    <upload-pdf-with-tokens-->
<!--                            *ngIf="this.tokensGenerated?.length"-->
<!--                            [tokensGenerated]="this.tokensGenerated"-->
<!--                            [uploadedLogo]="this.uploadedLogo"-->
<!--                            [updatedEvaluationId]="updatedEvaluationId"-->
<!--                            [updatedEvaluationTitle]="updatedEvaluationTitle"-->
<!--                            [createdEvaluationTitle]="this.createdEvaluationTitle"-->
<!--                            [createdEvaluationId]="this.createdEvaluationId"></upload-pdf-with-tokens>-->
<!--    <app-survey-creator-test-->
<!--                            *ngIf="this.createdEvaluationId"-->
<!--                             [createdEvaluationId]="createdEvaluationId"-->
<!--                             [updatedEvaluationSurvey]="updatedEvaluationSurvey"></app-survey-creator-test>-->
<!--    <app-survey-creator-test-->
<!--                            *ngIf="this.updatedEvaluation"-->
<!--                            [updatedEvaluationId]="updatedEvaluationId"-->
<!--                            [updatedEvaluationSurvey]="updatedEvaluationSurvey"></app-survey-creator-test>-->
</app-sidebar>