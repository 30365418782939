import {Component, Input, OnInit} from '@angular/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {DEFAULT_LOGO} from "../../../common/defaultLogo";
import {environment} from "../../../../environments/environment";
import {PdfService} from "../../../services/pdfService";
import {EvaluationService} from "../../../services/evaluation.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'upload-pdf-with-tokens',
    templateUrl: './upload-pdf-with-tokens.component.html',
    styleUrls: ['./upload-pdf-with-tokens.component.css']
})
export class UploadPdfWithTokensComponent implements OnInit {
    @Input() tokensGenerated: Array<any>;
    @Input() uploadedLogo: string;
    @Input() createdEvaluationTitle: string;
    @Input() createdEvaluationId: string;

    @Input() updatedEvaluationId: string | null;
    @Input() updatedEvaluationTitle: string;

    evaluationDescription: string = '';

    constructor(public pdfService: PdfService, public evaluationService: EvaluationService) {}

    defineRightTitleAndId() {
        if (this.updatedEvaluationTitle) {
            this.createdEvaluationTitle = this.updatedEvaluationTitle
        }
        if (this.updatedEvaluationId) {
            this.createdEvaluationId = this.updatedEvaluationId
        }
    }

    ngOnInit() {
        this.defineRightTitleAndId();
        const evaluationId = this.createdEvaluationId || this.updatedEvaluationId;
        if(evaluationId){
            this.evaluationService.getEvaluationById(evaluationId).subscribe(result => {
                this.evaluationDescription = result?.evaluationDescription;
            })
        }
    }

    generatePDF(action = 'open') {
        this.pdfService.generateTokensPDF({
            logo: this.uploadedLogo,
            tokens: this.tokensGenerated,
            evaluationId: this.createdEvaluationId,
            evaluationDescription: this.evaluationDescription,
            facilityName: 'test'
        })
    }
}