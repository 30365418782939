
<label for="field" class="field-label-2" >{{'loginWithToken' | translate}}</label>
<input  type="password" name="asdad" [(ngModel)]="_userAccessCode" class="textfield w-input" maxlength="256" name="field" data-name="Field" placeholder="{{'token' | translate }}" id="field" required="true">

<div class="w-layout-hflex flex-block">
    <div >
        <button type="button" class="prim-button w-inline-block buttonLogin" (click)="loginViaToken()" >{{'login' | translate}}</button>
    </div>

    <div>
        <a (click)="changeLoginOption()" [routerLink]="''" >{{'loginWithEmailLink' | translate}}</a>
    </div>
</div>

