import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {EvaluationService} from "../../../services/evaluation.service";
import {SurveyCreatorModel} from "survey-creator-core";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  haveCommercialLicense: true,
  // questionTypes: ["radiogroup", "text"]
}

@Component({
  selector: 'app-survey-creator-test',
  templateUrl: './survey-creator-test.component.html',
  styleUrls: ['./survey-creator-test.component.css']
})
export class SurveyCreatorTestComponent implements OnInit{
  @ViewChild('svc-json-editor-tab__content-area ng-untouched ng-pristine ng-valid') txtConfigFile: ElementRef;
  constructor(private  evaluationService: EvaluationService) {
  }
  @Input() createdEvaluationId: string;
  @Input() updatedEvaluationId: string | null;
  @Input() updatedEvaluationSurvey: string;

  surveyCreatorModel: SurveyCreatorModel;

  ngOnInit() {
    const creator = new SurveyCreatorModel(creatorOptions);
    creator.text = this.updatedEvaluationSurvey;
    creator["setRootElement"] = () => {};
    creator["unsubscribeRootElement"] = () => {};
    this.surveyCreatorModel = creator as SurveyCreatorModel;
    creator.saveSurveyFunc = () =>{
      if(this.createdEvaluationId){
        this.evaluationService.uploadSurvey(Number(this.createdEvaluationId), creator.JSON).subscribe(() => {
        })
      }
      if(this.updatedEvaluationId){
        this.evaluationService.uploadSurvey(Number(this.updatedEvaluationId), creator.JSON).subscribe(() => {
        })
      }

    }
  }
}
