import { Injectable } from '@angular/core';
import {Evaluation} from "../model/Evaluation";
import {Model} from "survey-angular";
import {environment} from '../../environments/environment';
import {ActivatedRoute} from "@angular/router";

/**
 * Copyright (C) 2016 - 2021 oparco - open architectures & consulting
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property
 * of oparco - open architectures & consulting and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary
 * to oparco - open architectures & consulting and its suppliers and may be
 * covered by foreign Patents, patents in process, and are protected by
 * trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from oparco - open architectures & consulting.
 */

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  private _activeEvaluation: Evaluation;

  public _configurationEnvironment = environment;

  //private _survey: Model;

  requestedEvaluationID: string;
  constructor(private route: ActivatedRoute) { }

  public get activeEvaluation(): Evaluation {
    return this._activeEvaluation;
  }

  public set activeEvaluation(value: Evaluation) {
    this._activeEvaluation = value;
  }

  /*public get survey(): Model {
    return this._survey;
  }

  public set survey(value: Model) {
    this._survey = value;
  }*/
}
