import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ContextService} from "./context.service";
import {Organization} from "../model/Organization";
import {Observable, of, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService{

  organizationServiceURL = "organizationService"

  constructor(private http: HttpClient, private  context: ContextService) { }

  private organizationCache: { [key: string]: Organization } = {};

  public getOrganizations(): Observable<any> {
    return this.http.get<any>(this.context._configurationEnvironment.service_engine_base_url + this.organizationServiceURL + '/organization', {
      headers: {'Content-Type': 'application/json'}
    });
  }

  public getOrganizationById(id: string): Observable<any>{
    return this.http.get<Organization>(this.context._configurationEnvironment.service_engine_base_url + this.organizationServiceURL + `/${id}`, {
      headers: {'Content-Type': 'application/json','Accept':'application/json'}
    })
  }

  public createOrganization(organization: string){
    return this.http.post<any>(this.context._configurationEnvironment.service_engine_base_url + this.organizationServiceURL, organization, {
      headers: {'Content-Type': 'application/json','Accept':'application/json'}
    }).pipe(
        tap(response => {
          this.organizationCache[response?.id] = response;
        })
    );
  }

  public updateOrganizationById(id: string, organization: string){
    return this.http.put<any>(this.context._configurationEnvironment.service_engine_base_url + this.organizationServiceURL + `/${id}`, organization, {
      headers: {'Content-Type': 'application/json','Accept':'application/json'}
    }).pipe(
        tap(response => {
          this.organizationCache[id] = response;
        })
    );
  }
}
