import {Component, OnInit} from '@angular/core';
import {NavigationService} from "../services/navigation.service";
import {Router} from "@angular/router";
import {CustomizationService} from "../services/customization.service";
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit{

  lang: string;
  defaultLang = 'de';

  selected: string ;
  ngOnInit() {
    if(localStorage.getItem('lang')){
      this.selected = String(localStorage.getItem('lang'))
    }
    else
      this.selected = this.defaultLang

    this.customizationService.loadStyle("/assets/styles-default.css")
  }

  logOutAndNavigateToLoginPage(){
    this.authenticationService.logOut()
    this._router.navigate([NavigationService.LOGINPAGE])
  }

  selectLanguage(){
    localStorage.setItem('lang', this.selected)
    window.location.reload()
  }

  constructor(private _router: Router,
              private customizationService: CustomizationService,
              private authenticationService: AuthenticationService
  ) {
  }
    protected readonly NavigationService = NavigationService;
}
