<div class="container">
    <p>
        <b>DATENSCHUTZERKLÄRUNG</b>
        <br>
        <br>
        <b>Wer wir sind<br>
        GEB „Gemeinnützige Gesellschaft für elementare Bildung mbH“<br>
            Institut für Qualifizierung, Entwicklung, Praxis und Begleitung</b><br>
        Agnetenstraße 14<br>
        39106 Magdeburg<br>
        Tel: 0391-79293-371<br>
        Funk: 0171-971 99 88<br>
        Email: info@gesellschaft-elementare-bildung.de<br>
        <br>
        <b>Registergericht</b><br>
        Amtsgericht Stendal<br>
        HRB: 26040<br>
        Geschäftsführer: Adrian Einecke<br>
        Die Adresse unserer Website ist: https://gesellschaft-elementare-bildung.de<br>
        Ihre Daten behandeln wir sehr sorgfältig. Die Erhebung, Verarbeitung und Nutzung Ihrer Daten erfolgt ausschließlich im Rahmen der gesetzlichen Bestimmungen.
        Diese Datenschutzerklärung gilt ausschließlich für die Nutzung der hier angebotenen Webseite. Sie gilt nicht für die Webseiten anderer Dienstanbieter, auf die wir lediglich durch einen Link verweisen.
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechts-grundlage.
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.6 Abs.1 lit. d DSGVO als Rechtsgrundlage.
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art.6 Abs.1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
        Sie bleiben bei der Nutzung unserer Webseiten anonym, solange Sie uns nicht von sich aus freiwillig personenbezogene Daten zur Verfügung stellen.
        Personenbezogene Daten werden nur dann erhoben, wenn dies für die Nutzung der auf der Webseite angebotenen Leistungen, insbesondere Formularangebote, erforderlich ist.
        Wir behandeln die von Ihnen zur Verfügung gestellten Daten streng vertraulich. Ohne Ihre ausdrückliche Einwilligung geben wir keine persönlichen Daten weiter, außer wir sind rechtlich dazu verpflichtet. Wir weisen jedoch darauf hin, dass es bei der Übermittlung von Daten im Internet immer dazu kommen kann, dass Dritte Ihre Daten zur Kenntnis nehmen oder verfälschen.
        Wir weisen darauf hin, dass die internetbasierte Datenübertragung Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch Dritte kann nicht gewährleistet werden.<br>
        <br>
        <b>Beschreibung und Umfang der Datenverarbeitung</b><br>
        Die Daten für die Umfragen werden mittels eines Fragebogens mit vorgefertigten Antwortkategorien erhoben und webbasiert anonym auf dem Server des Anbieters gespeichert. Dazu erhalten die Nutzer einen ihrer Nutzergruppe entsprechenden Zugangstoken. Dieser leitet die Nutzer auf die Website ??? und es öffnen sich Fragen, die individuell zu beantworten sind. Jeder Nutzer ist einer vom Auftraggeber definierten Nutzergruppe zugeordnet.
        Die erhobenen Daten werden ausschließlich für die Qualitätsentwicklung und Analyse der pädagogischen Arbeit der von der Umfrage betroffenen Einrichtung und ihres Trägers verwendet.
        Ein Personenbezug kann anhand der erhobenen Daten nicht hergestellt werden.
        Es werden somit ausschließlich anonyme, nicht personenbezogene Daten gespeichert und verarbeitet.
        Es sind keine Rückschlüsse auf Teilnehmer*innen der Umfrage möglich.
        Die Ergebnisse der Auswertung der Umfrage können im Rahmen von Teamsitzungen der betreffenden Einrichtung und des betreffenden Trägers genutzt werden. Dies betrifft insbesondere pädagogische Dienstberatungen, thematische Elternabende und Qualifizierungsveranstaltungen der Fachberatung der entsprechenden Einrichtung sowie Elterngremien. Die Präsentation der Ergebnisse der Umfrage geschieht in anonymisierter Form und einrichtungsspezifisch.
        Es können nur Rückschlüsse auf die gesamte Nutzergruppen gezogen werden, nicht jedoch auf einzelne Personen.<br>
        <br>
        <b>Datenlöschung und Speicherdauer</b><br>
        Die erhobenen Daten der Nutzergruppen werden auf Verlangen des Auftraggebers gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
        <br><br>
        <b>Widerspruchs- und Beseitigungsmöglichkeit</b><br>
        Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
        Auf Wunsch gewähren wir Einblick in die über Sie als Nutzergruppe gespeicherten Daten. Vom einzelnen Nutzer eingegebene Daten zu berichtigen, zu löschen oder einsehen zu wollen ist nur mit der Nutzung eines gültigen Zugangstokens möglich. Mit dem Abschluss der Umfrage ist dies nicht mehr möglich.
        Aufgrund der Funktion der Daten, liegen diese nicht als einzelne rückverfolgbare Daten vor und können nicht einzeln bestimmt und gelöscht werden.
        Bei Nachfragen bitten wir um eine schriftliche Mitteilung an die im Impressum angegebene Anschrift oder an den Datenschutzbeauftragten.
        <br><br>
        <b>Die verantwortliche Stelle für die Datenverarbeitung:</b><br>
        GEB „Gemeinnützige Gesellschaft für elementare Bildung mbH“<br>
        Institut für Qualifizierung, Entwicklung, Praxis und Begleitung<br>
        Agnetenstraße 14<br>
        39106 Magdeburg<br>
        Tel: 0391-79293-371<br>
        Funk: 0171-971 99 88<br>
        Email: info@gesellschaft-elementare-bildung.de<br>
        <br>
        <b>Datenschutzbeauftragte:</b><br>
        Stiftung Evangelische Jugendhilfe St. Johannis Bernburg<br>
        Dr.-John-Rittmeister-Straße 6<br>
        06406 Bernburg (Saale)<br>
        E-Mail: datenschutz@stejh.de<br>
        <br>
        <b>Website</b><br>
        Den Zugang zur Befragung auf unserer Website ???? erhalten Sie durch einen anonymen QR-Code bzw. einen anonymen Zugangstoken. Die Website dient ausschließlich der Eingabe von nicht personenbezogenen Daten im Rahmen von Umfragen.
        <br><br>
        <b>Cookies und Wiedererkennung der Website</b><br>
        Wir verwenden auf der Website der Umfragen keine sogenannten Cookies. Die Wiedererkennung des Zugangs zur Befragung erfolgt durch einen anonymisierten Zugangscode. Nach Beendigung der Umfrage erlischt die Funktionsfähigkeit des Zugangscodes und er kann nicht weiterverwendet werden.
        <br><br>
        <b>Serverdaten</b><br>
        Es werden von der Website ???? keine IP-Adressen gespeichert.<br>
        <br>
        <b>Kontakt E-Mail-Kontakt</b><br>
        Eine Kontaktaufnahme mit dem Anbieter und/ oder Auftraggeber der Umfrage erfolgt ausschließlich über deren eigene Website oder direkt mittels Emailprogramm, nicht über die Website der Umfrage. Damit unterliegen die erhobenen Daten der Emailkontaktaufnahme den Datenschutzbestimmungen der Website der Anbieter und/ oder Auftraggeber.
        <br><br>
        <b>Recht auf Einschränkung der Verarbeitung</b><br>
        Durch die Anonymität und nicht Rückverfolgbarkeit der Angaben gibt es ein Recht auf Einschränkung. Dementsprechend steht es den Nutzern frei, zu einzelnen bzw. auch zu allen Fragen keine Angaben zu machen.
        <br><br>
        <b>Löschungspflicht</b><br>
        Eine Löschpflicht personenbezogenen Daten entfällt, da keine personenbezogenen Daten erhoben werden.
        <br><br>
        <b>Information an Dritte</b><br>
        Die Ergebnisse der Umfrage werden nur mit Erlaubnis der betreffenden Einrichtung und des Trägers an Dritte weiterzugeben.
        <br><br>
        <b>Recht auf Unterrichtung</b><br>
        Alle Teilnehmenden haben das Recht auf Unterrichtung der verarbeiteten Ergebnisse der Umfrage. Dies erfolgt im Rahmen der Arbeit der Auftraggeber.
        <br><br>
        <b>Recht auf Datenübertragbarkeit</b><br>
        Es werden keine verarbeiteten Ergebnisse ohne Zustimmung der Auftraggeber übertragen.
        <br><br>
        <b>Recht a`uf Beschwerde bei einer Aufsichtsbehörde</b><br>
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
        <br><br>
        <br>
        Die Datenschutzerklärung tritt am 01.06.2024 in Kraft. Die Datenschutzerklärung liegt in der jeweils aktuellen Fassung vor. Eine Änderung dieser ist während einer laufenden Umfrage nicht vorgesehen.
        <br>
    </p>
</div>

