import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {catchError} from 'rxjs/operators';
import * as moment from "moment/moment";
import {ContextService} from "./context.service";
import {Evaluation} from "../model/Evaluation";
import {TokenLogin} from "../model/TokenLogin";
import {EmailLogin} from "../model/EmailLogin";
import {SnackBarService} from "./snack-bar.service";
import {TranslateService} from "@ngx-translate/core";
import {NavigationService} from "./navigation.service";
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    authnServiceTokenURL: string = "authn/tokenAuthnService/tokenLogin";
    authnServiceLoginURL: string = "authn/tokenAuthnService/usernameLogin";

    loginWithEmail: boolean = false;

    isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient,
                private translate: TranslateService,
                private context: ContextService,
                private router: Router,
                private snackBarService: SnackBarService) {
    }

    tokenRequest(token: string, evaluation: Evaluation): Observable<any> {

        let header = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.context._configurationEnvironment.service_engine_base_url + this.authnServiceTokenURL, new TokenLogin(token, evaluation.id), {headers: header})
            .pipe(catchError((): Observable<any> => {
                this.snackBarService.error(this.translate.instant('loginFailed'))
                // after handling the error, return a new observable
                // that doesn't emit any values and completes
                throw new Error("Login failed.");
            }))
    }


    loginViaToken(accessCode: string, evaluation: Evaluation) {
        try {
            this.tokenRequest(accessCode, evaluation).subscribe(data => {
                this.setToken(data.access_token)
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem("expires_in", JSON.stringify(data.expires_in.valueOf()) );
                localStorage.setItem("expires_at", moment(moment.now()).add(data.expires_in, 'seconds').toLocaleString());
                this.context.activeEvaluation = evaluation;
                if(localStorage.getItem("token")){
                    this.router.navigate([NavigationService.SURVEYPAGE + '/' + evaluation.id?.toString()]).then()
                }
            });
        }
        catch (Error){
            // this.router.navigate()
            this.snackBarService.error(this.translate.instant('loginFailed'))
        }
    }



    loginViaEmail(email: string, password: string): Observable<any> {

        let header = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.context._configurationEnvironment.service_engine_base_url + this.authnServiceLoginURL, new EmailLogin(email, password), {headers: header})
            .pipe(catchError((): Observable<any> => {
                this.snackBarService.error(this.translate.instant('loginFailedAdmin'))
                // after handling the error, return a new observable
                // that doesn't emit any values and completes
                throw new Error("Login failed.");
            }))
    }

    public logOut() {
        this.isAdmin.next(false);
        localStorage.removeItem("token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration: string = localStorage.getItem("expires_at") || '{}';
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    public logoutByInactivity() {
        console.log('user-session expired, unpersonalized access_token removed from localStorage.');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('expires_in');
    }

    // STORE the token in localstore:
    setToken(token: string) {
        localStorage.setItem('token', token);
    }

// READ the token from localstorage and Deserialize
    getToken(): string | null {
        return localStorage.getItem('token');
    }
}