import {Component, Input} from '@angular/core';
import {NavigationService} from "../services/navigation.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../services/authentication.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
    constructor(public authenticationService: AuthenticationService, private breakpointObserver: BreakpointObserver){ }
    @Input()
    dynamicChild: any;
    protected readonly NavigationService = NavigationService;

    isSideNavOpened = true;

    toggleSidebar() {
      this.isSideNavOpened = !this.isSideNavOpened;
    }


    ngOnInit() {
        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe(result => {
            if (result.matches) {
                this.isSideNavOpened = false;
            } else {
                this.isSideNavOpened = true;
            }
        });
    }

}