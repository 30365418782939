<div class="navbar-logo-center container">
    <div data-animation="default" data-collapse="all" data-duration="400" data-easing="ease" data-easing2="ease" role="banner">
        <div class="main">
            <div (click)="logOutAndNavigateToLoginPage()" class="logosContainer">
                <img src="assets/logo.png" loading="lazy" alt="" class="image-4">
                <img src="assets/soconic.webp" loading="lazy" alt="" class="image-3">
            </div>
            <div class="languageSelect" >
                <mat-form-field appearance="outline" >
                    <mat-icon matIconPrefix>language</mat-icon>

                    <mat-select [(ngModel)]="selected" (ngModelChange)="selectLanguage()">
                        <mat-option lang="de" value="de">Deutsch</mat-option>
                        <mat-option lang="en" value="en">English</mat-option>
                        <mat-option lang="ar" value="ar">عرب</mat-option>
                        <mat-option lang="uk" value="uk">Українська</mat-option>
                        <mat-option lang="ru" value="ru">Русский</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
