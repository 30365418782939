<div class="main">
    <div class="header">
        <result-test-filters
                [facilities]="this?.currentEvaluation?.value?.facilities || []"
                [entities]="['EMPLOYEE', 'CUSTOMER', 'MANAGEMENT', 'TEAM']"
                [tokenDictionary]="this?.currentEvaluation?.value?.tokenDictionary || {}"
        ></result-test-filters>
        <button
                class="downloadButton" (click)="handleExport()"
                [disabled]="this.isPdfLoading.value"
        >
            <app-loader *ngIf="isPdfLoading.value"></app-loader>
            <span *ngIf="!isPdfLoading.value">Download pdf</span>
        </button>
    </div>
    <div class="diagramBody" id="pdf_container" *ngIf="this.evaluationId">
        <result-common-info [evaluationId]="this.evaluationId"></result-common-info>

        <mat-tab-group class="tabs">
            <mat-tab *ngFor="let category of categories; index as categoryIndex" [label]="category?.title">
                <!--                <div id="surveyVizPanel"></div>-->
                <div class="customDiagramContainer">
                    <div *ngFor="let question of category?.graphDataAll; let i = index" class='customDiagram'>
                        <plotly-plot *ngIf="question?.type !== 'table'" [id]="'plot' + category?.id + i"
                                     [data]="getDataFromQuestionsDiagram(question)"
                                     [divId]="'plot' + category?.id + i"
                                     [layout]="question?.layout"></plotly-plot>

                        <div  *ngIf="question?.type == 'table'" class="questionWithOpenAnswer">
                            <div [innerHTML]="question?.layout?.title?.text" class="openQuestionTitle"></div>
                            <table mat-table [dataSource]="question?.dataSource"
                                   class="answersTable" id="generalResultsTableQuestion">
                                <ng-container [matColumnDef]="column" *ngFor="let column of question?.displayedColumns">
                                    <th mat-header-cell *matHeaderCellDef class="firstColumn"> {{column}} </th>
                                    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="question?.displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: question?.displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 generalResultsTable" id="generalResultsTable">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>