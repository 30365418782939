import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {TokenLoginComponent} from './login-page/token-login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SurveyComponent} from './survey/survey.component';
import {AuthenticationService} from "./services/authentication.service";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BnNgIdleService} from 'bn-ng-idle';
import {ResultComponent} from './survey/resultDashboard/result.component';
import {AdminPageComponent} from './admin-page/admin-page.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import {PlotlyModule} from 'angular-plotly.js';
import {SurveyCreatorComponent} from './survey/survey-creator/survey-creator.component';
import {SurveyCreatorModule} from 'survey-creator-angular';
import {AppRoutingModule} from "./app-routing.module";
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {TokenInterceptor} from "./token.interceptor";
import {EvaluationListComponent} from './login-page/evaluation-list/evaluation-list.component';
import {LoginTokenComponent} from './login-page/login-token/login-token.component';
import {LoginEmailComponent} from './login-page/login-email/login-email.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {SpinnerComponent} from './spinner/spinner.component';
import {LoadingInterceptor} from "./loading.interceptor";
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AskForIdDialogComponent} from './survey/resultDashboard/ask-for-id-dialog/ask-for-id-dialog.component';
import {DialogService} from "./services/dialog.service";
import {ResultTestComponent} from './survey/resultDashboard/result-test/result-test.component';
import {EvaluationCreatorComponent} from './survey/survey-creator/evaluation-creator/evaluation-creator.component';
import {SurveyCreatorTestComponent} from './survey/survey-creator/survey-creator-test/survey-creator-test.component';
import {EvaluationUpdateComponent} from './survey/survey-creator/evaluation-update/evaluation-update.component';
import {
    ResultTestFiltersComponent
} from "./survey/resultDashboard/result-test/result-rest-filters/result-test-filters.component";
import {
    UploadPdfWithTokensComponent
} from "./survey/survey-creator/upload-pdf-with-tokens/upload-pdf-with-tokens.component";
import { PublishEvaluationComponent } from './survey/survey-creator/publish-evaluation/publish-evaluation.component';
import {
    ResultCommonInfoComponent
} from "./survey/resultDashboard/result-test/result-common-info/result-common-info.component";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material/select";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatTabsModule} from "@angular/material/tabs";
import { NgxEditorModule} from 'ngx-editor';
import {FaqComponent} from "./faq/faq.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {FaqPreviewDialogComponent} from "./survey/survey-creator/faq-preview-dialog/faq-preview-dialog.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {FinishSurveyComponent} from "./finish-survey/finish-survey.component";
import { SearchFilterPipe } from './search-filter.pipe';
import { WarningModalDialogComponent as WarningModalDialogComponent } from './warning-modal-dialog/warning-modal-dialog.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationDetailsComponent } from './organizations/organization-details/organization-details.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { OrganizationCardComponent } from './organizations/organization-card/organization-card.component';
import { OrganizationCreatorComponent } from './organizations/organization-creator/organization-creator.component';
import { FacilitiesListComponent } from './organizations/facilities-list/facilities-list.component';
import { FacilityDetailsComponent } from './organizations/facility-details/facility-details.component';
import { FacilityCreatorComponent } from './organizations/facility-creator/facility-creator.component';
import {OrganizationListComponent} from "./organization-list/organization-list.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { GeoChooserComponent } from './organizations/facility-creator/geo-chooser/geo-chooser.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

import {TokensComponent} from "./tokens/tokens.component";
import { ListOfFacilitiesAndTokensQuantityChooserComponent } from './survey/survey-creator/list-of-facilities-and-tokens-quantity-chooser/list-of-facilities-and-tokens-quantity-chooser.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { SurveyRepresentationComponent } from './survey-representation/survey-representation.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatSliderModule} from "@angular/material/slider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { JwtModule } from "@auth0/angular-jwt";
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import {DesignerComponent} from "./designer/designer.component";

PlotlyModule.plotlyjs = PlotlyJS;

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        TokenLoginComponent,
        SurveyComponent,
        ResultComponent,
        AdminPageComponent,
        SurveyCreatorComponent,
        HeaderComponent,
        FooterComponent,
        EvaluationListComponent,
        LoginTokenComponent,
        LoginEmailComponent,
        SpinnerComponent,
        LoaderComponent,
        AskForIdDialogComponent,
        ResultTestComponent,
        EvaluationCreatorComponent,
        SurveyCreatorTestComponent,
        EvaluationUpdateComponent,
        ResultTestFiltersComponent,
        UploadPdfWithTokensComponent,
        PublishEvaluationComponent,
        ResultCommonInfoComponent,
        FaqComponent,
        FinishSurveyComponent,
        FaqPreviewDialogComponent,
        SidebarComponent,
        SearchFilterPipe,
        WarningModalDialogComponent,
        OrganizationsComponent,
        TokensComponent,
        OrganizationDetailsComponent,
        SearchBarComponent,
        OrganizationCardComponent,
        OrganizationCreatorComponent,
        FacilitiesListComponent,
        FacilityDetailsComponent,
        FacilityCreatorComponent,
        FacilitiesListComponent,
        OrganizationListComponent,
        GeoChooserComponent,
        ListOfFacilitiesAndTokensQuantityChooserComponent,
        SurveyRepresentationComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        DesignerComponent
    ],
    imports: [
        MatNativeDateModule,
        MatDialogModule,
        MatButtonModule,
        MatSidenavModule,
        BrowserModule,
        FormsModule,
        CommonModule,
        PlotlyModule,
        SurveyCreatorModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter:  () => localStorage.getItem('access_token')
            }
        }),
        NgOptimizedImage,
        ReactiveFormsModule,
        MatExpansionModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatTabsModule,
        MatInputModule,
        MatFormFieldModule,
        NgxEditorModule.forRoot({
            locals: {
                // menu
                bold: 'Bold',
                italic: 'Italic',
                code: 'Code',
                blockquote: 'Blockquote',
                underline: 'Underline',
                strike: 'Strike',
                bullet_list: 'Bullet List',
                ordered_list: 'Ordered List',
                heading: 'Heading',
                h1: 'Header 1',
                h2: 'Header 2',
                h3: 'Header 3',
                h4: 'Header 4',
                h5: 'Header 5',
                h6: 'Header 6',
                align_left: 'Left Align',
                align_center: 'Center Align',
                align_right: 'Right Align',
                align_justify: 'Justify',
                text_color: 'Text Color',
                background_color: 'Background Color',
                url: 'URL',
                text: 'Text',
                openInNewTab: 'Open in new tab',
                insert: 'Insert',
                altText: 'Alt Text',
                title: 'Title',
                remove: 'Remove',
            },
        }),
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatCardModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatTableModule,
        MatDatepickerModule,
        MatRadioModule,
        MatSliderModule,
        MatCheckboxModule
    ],
    providers: [AuthenticationService, BnNgIdleService, DialogService,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        HttpClient],
    bootstrap: [AppComponent]
})

export class AppModule {
}
