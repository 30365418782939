
<div id="modal-content-wrapper">
    <header id="modal-header">
        <h1 id="modal-title">{{modalMessage}}</h1>
    </header>
    <section id="modal-body">
        <h1>{{modalWarning}}</h1>
    </section>
    <footer id="modal-footer">

        <button mat-raised-button id="modal-cancel-button" (click)="closeModal()">{{'goBack' | translate}}</button>
        <button mat-raised-button id="modal-action-button" (click)="actionFunction()">{{modalButtonName}}</button>

    </footer>
</div>
