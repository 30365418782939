import {inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationService} from "./authentication.service";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {

    constructor(private router: Router, private authenticationService: AuthenticationService) {}

    isAdmin(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authenticationService.isAdmin.value
    }
}

export const AdminGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(PermissionsService).isAdmin(next, state);
}