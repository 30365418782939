import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { debounceTime } from "rxjs";
import { EvaluationService } from "../services/evaluation.service";
import { SnackBarService } from "../services/snack-bar.service";
import { Router } from "@angular/router";
import { NavigationService } from "../services/navigation.service";

@Component({
  selector: "app-designer",
  templateUrl: "./designer.component.html",
  styleUrls: ["./designer.component.css"],
})
export class DesignerComponent implements OnInit {
  @Input() evaluationId: string;
  @Input() tokensDictionary: Record<string, string> | null;

  questionTypes: Array<Record<string, string>> = [
    { value: "RADIO", viewValue: "Radio" },
    { value: "BOOLEAN", viewValue: "Boolean" },
    { value: "RANGE", viewValue: "Range" },
    { value: "TEXT", viewValue: "Text" },
    { value: "MULTISELECT", viewValue: "Multiselect" },
  ];

  languages = [
    { code: "de", label: "DE", placeholder: "Please write in German" },
    { code: "en", label: "EN", placeholder: "Please write in English" },
    { code: "uk", label: "UA", placeholder: "Please write in Ukrainian" },
    { code: "ru", label: "RU", placeholder: "Please write in Russian" },
    { code: "ar", label: "AR", placeholder: "Please write in Arabic" },
  ];

  tokenTypes: Array<{ code: string; label: string }> = [];

  myForm: FormGroup;

  constructor(
    private evaluationService: EvaluationService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.tokenTypes = [
      {
        code: "CUSTOMER",
        label: this.tokensDictionary?.["CUSTOMER"] || "Customer",
      },
      {
        code: "EMPLOYEE",
        label: this.tokensDictionary?.["EMPLOYEE"] || "Employee",
      },
      { code: "TEAM", label: this.tokensDictionary?.["TEAM"] || "Team" },
      {
        code: "MANAGEMENT",
        label: this.tokensDictionary?.["MANAGEMENT"] || "Management",
      },
    ];

    this.myForm = this.fb.group({
      title: ["", Validators.required],
      pages: this.fb.array([]),
    });

    this.subscribeFormValues();
    this.getSurveyDraft();
  }

  //@TODO potentially wrong approach
  getSurveyDraft() {
    if (this.evaluationId) {
      this.evaluationService
        .readSurveyDraftOfEvaluation(this.evaluationId)
        .subscribe((result) => {
          const data = JSON.parse(result);
          this.myForm.patchValue({
            title: data?.title,
          });

          if (data?.pages?.length) {
            data?.pages?.forEach((page) => {
              this.pages.push(
                this.fb.group({
                  title: this.getTitlesForNewEntity(page?.title),
                  questions: page?.questions?.length
                    ? this.fb.array(
                        page?.questions?.map((question) =>
                          this.fb.group({
                            tips: this.getTitlesForNewEntity(question?.tips),
                            indicator: [
                              question?.indicator || "",
                              Validators.required,
                            ],
                            type: [question?.type || "RADIO", []],
                            title: this.fb.group({
                              EMPLOYEE: this.getTitlesForNewEntity(
                                question?.title?.["EMPLOYEE"],
                              ),
                              CUSTOMER: this.getTitlesForNewEntity(
                                question?.title?.["CUSTOMER"],
                              ),
                              TEAM: this.getTitlesForNewEntity(
                                question?.title?.["TEAM"],
                              ),
                              MANAGEMENT: this.getTitlesForNewEntity(
                                question?.title?.["MANAGEMENT"],
                              ),
                            }),
                            options: question?.options?.length
                              ? this.fb.array(
                                  question?.options?.map((option) =>
                                    this.fb.group({
                                      value: option?.value || 0,
                                      title: this.getTitlesForNewEntity(
                                        option?.title,
                                      ),
                                    }),
                                  ),
                                )
                              : this.fb.array([]),
                          }),
                        ),
                      )
                    : this.fb.array([]),
                }),
              );
            });
          }
          console.log("form values>>", this.myForm.value);
        });
    }
  }

  //@TODO reuse for arrays
  getTitlesForNewEntity(entity?: any): FormGroup {
    return this.fb.group({
      ru: [entity?.ru || "", []],
      de: [entity?.de || "", Validators.required],
      en: [entity?.en || "", []],
      ar: [entity?.ar || "", []],
      uk: [entity?.uk || "", []],
    });
  }

  addPage() {
    this.pages.push(
      this.fb.group({
        title: this.getTitlesForNewEntity(),
        questions: this.fb.array([]),
      }),
    );
  }

  addQuestion(pageIndex: number) {
    this.getQuestions(pageIndex).push(
      this.fb.group({
        tips: this.getTitlesForNewEntity(),
        indicator: ["", Validators.required],
        type: ["RADIO", []],
        title: this.fb.group({
          EMPLOYEE: this.getTitlesForNewEntity(),
          CUSTOMER: this.getTitlesForNewEntity(),
          TEAM: this.getTitlesForNewEntity(),
          MANAGEMENT: this.getTitlesForNewEntity(),
        }),
        options: this.fb.array([]),
      }),
    );
  }

  addQuestionOption(pageIndex: number, questionIndex: number) {
    this.getQuestionOptions(pageIndex, questionIndex).push(
      this.fb.group({
        value: 0,
        title: this.getTitlesForNewEntity(),
      }),
    );
  }

  removePage(index: number) {
    this.pages.removeAt(index);
  }

  removeQuestion(pageIndex, questionIndex) {
    this.getQuestions(pageIndex).removeAt(questionIndex);
  }

  removeQuestionsOption(pageIndex, questionIndex, optionIndex) {
    this.getQuestionOptions(pageIndex, questionIndex).removeAt(optionIndex);
  }

  get pages() {
    return this.myForm.get("pages") as FormArray;
  }

  getQuestions(pageIndex: number) {
    return this.pages.at(pageIndex).get("questions") as FormArray;
  }

  getQuestionOptions(pageIndex: number, questionIndex) {
    return this.getQuestions(pageIndex)
      .at(questionIndex)
      .get("options") as FormArray;
  }

  saveSurvey() {
    const dataToSend = {
      creationDate: new Date(),
      title: this.myForm.value?.title,
      pages: this.myForm.value?.pages?.map((page) => ({
        titles: this.evaluationService.mapEntityTitlesReverse(page?.title),
        questions: page?.questions?.map((question) => ({
          type: question?.type,
          indicator: question?.indicator,
          tips: this.evaluationService.mapEntityTitlesReverse(question?.tips),
          titles: this.evaluationService.mapEntityTokenTypeTitles(
            question?.title,
          ),
          options: question?.options?.map((option) => ({
            value: String(option?.value),
            titles: this.evaluationService.mapEntityTitlesReverse(
              option?.title,
            ),
          })),
        })),
      })),
    };
    console.log("Data to send>>>", dataToSend);
    return dataToSend;
  }

  onSubmit() {
    // console.log("this.saveSurvey();", this.saveSurvey();)

    this.evaluationService
      .uploadSurvey(Number(this.evaluationId), this.saveSurvey())
      .subscribe((response) => {
        this.evaluationService
          .changeEvaluationState(this.evaluationId, "PUBLISHED")
          .subscribe(
            () => {},
            (error) => {
              this.snackbarService.error("Failed to publish evaluation");
            },
          );

        this.evaluationService
          .uploadSurveyDraft(this.evaluationId, "  ")
          .subscribe(
            () => {},
            (error) => {
              this.snackbarService.error("Failed to save survey draft");
            },
          );

        this.router
          .navigate([NavigationService.CREATORPAGE + "/" + this.evaluationId])
          .then();
      });
  }

  subscribeFormValues() {
    this.myForm.valueChanges.pipe(debounceTime(3000)).subscribe((value) => {
      console.log("this.evaluationId", this.evaluationId);
      if (this.evaluationId) {
        this.evaluationService
          .uploadSurveyDraft(this.evaluationId, JSON.stringify(value))
          .subscribe(
            () => {},
            (error) => {
              this.snackbarService.error("Failed to save survey draft");
            },
          );
      }
    });
  }

  protected readonly JSON = JSON;
}
