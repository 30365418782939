import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-faq-preview-dialog',
    templateUrl: './faq-preview-dialog.component.html',
    styleUrls: ['./faq-preview-dialog.component.css'],
})
export class FaqPreviewDialogComponent {
    public message: string = ""
    cancelButtonText = "Cancel"
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public sanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<FaqPreviewDialogComponent>) {
        if (data) {
            this.message = data.message || this.message;
            if (data.buttonText) {
                this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
            }
        }
        this.dialogRef.updateSize('300vw','100vh')
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }

}