import {Component, OnInit} from '@angular/core';
import {NavigationService} from "../services/navigation.service";
import {Router} from "@angular/router";
import {CustomizationService} from "../services/customization.service";
import {ContextService} from "../services/context.service";
import {EvaluationService} from "../services/evaluation.service";
import {AuthenticationService} from "../services/authentication.service";
import {Faq} from "../services/faqService";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Evaluation} from "../model/Evaluation";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
    constructor(public sanitizer: DomSanitizer,
                private evaluationService: EvaluationService,) {}

    faq: Partial<Faq> = {};
    currentLanguage: string;
    public currentEvaluation: Evaluation | null = null;

    ngOnInit() {
        const faqFromLocalStorage = localStorage.getItem('faq');
        if (faqFromLocalStorage != null) {
            sessionStorage.setItem('faq', faqFromLocalStorage);
            this.faq = JSON.parse(faqFromLocalStorage);
        }

        const faqFromSessionStorage = sessionStorage.getItem('faq');
        if (faqFromSessionStorage != null) {
            this.faq = JSON.parse(faqFromSessionStorage);
        }

        const languageFromLocalStorage = localStorage.getItem('lang');
        this.currentLanguage = languageFromLocalStorage || 'de';
        localStorage.removeItem('faq');

        const currentEvaluationID = localStorage.getItem('currentEvaluationId');
        if (currentEvaluationID) {
            this.evaluationService.getEvaluationById(currentEvaluationID).subscribe(evaluation => {
                    this.currentEvaluation = evaluation;
                }
            )
        }
    }
}
