import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ContextService} from "./context.service";

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService {

  facilityService = "facilityService"

  constructor(private http: HttpClient,
              private  context: ContextService) {}

  public createFacility(facility: string): Observable<any>{
    return this.http.post<any>(this.context._configurationEnvironment.service_engine_base_url + this.facilityService, facility, {
      headers: {'Content-Type': 'application/json','Accept':'application/json'}
    } )
  }

  public getFacilityById(id: string): Observable<any>{
    return this.http.get<any>(this.context._configurationEnvironment.service_engine_base_url + this.facilityService + '/' + id, {
      headers: {'Content-Type': 'application/json'}
    })
  }

  public updateFacilityById(id: string, facility: string): Observable<any>{
    return this.http.put<any>(this.context._configurationEnvironment.service_engine_base_url + this.facilityService + '/' + id, facility, {
      headers: {'Content-Type': 'application/json','Accept':'application/json'}
    })
  }
}
