import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../services/organization.service";
import {NavigationService} from "../services/navigation.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit {

  allOrganizations: any
  searchTerm: string


  constructor(private organizationService: OrganizationService,
              private router: Router) {
  }

  ngOnInit() {
    this.getOrganizations()
  }

  getOrganizations(){
    this.organizationService.getOrganizations().subscribe(response => {
      this.allOrganizations = response
    })
  }

  receiveSearchTerm(event: string){
    this.searchTerm = event
  }

  goToOrganizationDetails(organizationId: number){
    this.router.navigate([NavigationService.ORGANIZATION + '/' + organizationId?.toString()]).then()
  }

  goToOrganizationCreation(){
    this.router.navigate([NavigationService.ORGANIZATIONCREATOR]).then()
  }

}
