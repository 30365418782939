<div class="container">
    <h2>Select geographic data</h2>
    <div class="inline-fields">
        <div class="one-fourth-width">
            <mat-form-field class="mat-form-field" appearance="outline">
                <mat-label>Select Country</mat-label>
                <mat-select [(ngModel)]="country">
                    <mat-option *ngFor="let country of countryModel" [value] = "country">{{country.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="country" class="one-fourth-width">
            <mat-form-field class="mat-form-field" appearance="outline">
                <mat-label>Select State</mat-label>
                <mat-select [(ngModel)]="state">
                    <mat-option *ngFor="let state of country.states" [value] = "state">{{state.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="country && state" class="one-fourth-width">
            <mat-form-field class="mat-form-field" appearance="outline">
                <mat-label>Select County</mat-label>
                <mat-select [(ngModel)]="county">
                    <mat-option *ngFor="let county of state.counties" [value] = "county">{{county.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="country && state && county" class="one-fourth-width">
            <mat-form-field class="mat-form-field" appearance="outline">
                <mat-label>Select Community</mat-label>
                <mat-select [(ngModel)]="community" (selectionChange)="handleCommunitySelect()">
                    <mat-option *ngFor="let community of county.communities" [value] = "community" >{{community.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
