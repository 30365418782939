import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ResultRequestFilters} from "../model/ResultRequestFilters";

@Injectable({
    providedIn: 'root',
})
export class ResultTestFiltersService {
    public filters$: BehaviorSubject<ResultRequestFilters> = new BehaviorSubject<ResultRequestFilters>({
        facilityIds: [], entities: []
    })
    constructor() {}
}