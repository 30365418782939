import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../services/organization.service";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.css']
})
export class OrganizationDetailsComponent implements OnInit {

  organizationId: string | null
  organization: any
  searchTerm :string

  constructor(private route: ActivatedRoute,
              private router: Router,
              private organizationService: OrganizationService){
  }

  ngOnInit() {
    this.getOrganizationId()
    this.getOrganizationById(this.organizationId? this.organizationId : "")
  }

  getOrganizationId(){
    this.organizationId = this.route.snapshot.paramMap.get('organizationId');
  }

  getOrganizationById(organizationId: string) {
    this.organizationService.getOrganizationById(organizationId).subscribe(response => {
      this.organization = response
    })
  }

  receiveSearchTerm(event: string){
    this.searchTerm = event
  }

  goToOrganizationEditing(){
    this.router.navigate([NavigationService.ORGANIZATIONCREATOR + '/' + this.organizationId])
  }

  goToFacilityCreation(){
    this.router.navigate([NavigationService.FACILITYCREATOR], {relativeTo:this.route})
  }

}
