import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EvaluationService} from "../../../services/evaluation.service";
import {FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {Evaluation} from "../../../model/Evaluation";
import {BehaviorSubject} from "rxjs";
import {calculateDateDifference} from "../../../common/utils/calculateDateDifference";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {SnackBarService} from "../../../services/snack-bar.service";
import {Editor, Toolbar} from "ngx-editor";
import {Faq} from "../../../services/faqService";
import {FaqPreviewDialogComponent} from "../faq-preview-dialog/faq-preview-dialog.component";
import {OrganizationListComponent} from "../../../organization-list/organization-list.component";


@Component({
    selector: 'app-evaluation-update',
    templateUrl: './evaluation-update.component.html',
    styleUrls: ['./evaluation-update.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrganizationListComponent),
            multi: true
        }
    ]
})


export class EvaluationUpdateComponent implements OnInit, OnDestroy {
    @Output() CreatedEvaluationId = new EventEmitter<string>
    @Output() UploadedLogo = new EventEmitter<string>
    @Output() CreatedEvaluationTitle = new EventEmitter<string>
    @Output() UpdatedEvaluationSurvey = new EventEmitter<string>

    @Output() UpdatedEvaluation = new EventEmitter<string>
    @Output() UpdatedEvaluationId = new EventEmitter<string>
    @Output() UpdatedEvaluationTitle = new EventEmitter<string>
    @Output() UpdatedEvaluationFacilities = new EventEmitter<any>

    @Input() updatingEvaluationId: string;


    // createdEvaluationID: string;
    createdEvaluationTitle: string;
    base64textString: string;
    tokensDictionary: Record<string, string> | null = null;


    myForm: FormGroup;
    updatingEvaluation: Evaluation;
    // updatingEvaluationId: string | null;
    updatingEvaluationSurvey: string | null;
    submitButtonDisabled = false
    logoFromServer: string;

    tokenTypes = [
        {code: 'CUSTOMER', label: 'Customer'},
        {code: 'EMPLOYEE', label: 'Employee'},
        {code: 'TEAM', label: 'Team'},
        {code: 'MANAGEMENT', label: 'Management'},
    ];

    public selectedTab: BehaviorSubject<string> = new BehaviorSubject<string>('de');
    minimalDateStart = calculateDateDifference(new Date(), -1);
    minimalDateEnd = calculateDateDifference(new Date(), 2);

    constructor(private evaluationService: EvaluationService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private snackBarService: SnackBarService
    ) {
    }

    editorDe: Editor;
    editorEn: Editor;
    editorRu: Editor;
    editorUa: Editor;
    editorAr: Editor;

    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    ngOnDestroy(): void {
        this.editorDe.destroy();
        this.editorEn.destroy();
        this.editorRu.destroy();
        this.editorUa.destroy();
        this.editorAr.destroy();
    }

    ngOnInit() {
        this.editorDe = new Editor();
        this.editorEn = new Editor();
        this.editorRu = new Editor();
        this.editorUa = new Editor();
        this.editorAr = new Editor();
        //@TODO connect date validators (function is ready, common/utils/dateValidators)
        this.myForm = this.fb.group({
            name: ["", Validators.required],
            startdate: ["", Validators.required],
            enddate: ["", Validators.required],
            customization: ["assets/styles.css", Validators.required],
            logo: [null, []],
            organization: [null, Validators.required],
            // generatedEmployeeTokens: [null, []],
            // generatedCustomerTokens: [null, []],
            evaluationDescription: ['', []],
            creationdate: [null, []],
            updatedate: [null, []],
            faq: this.fb.group({ // make a nested group
                ru: '',
                de: '',
                en: '',
                uk: '',
                ar: '',
            }),
            facilities: this.fb.array([
                this.saveTokensForFacility()
            ]),
            customTokenTypeTitles: this.fb.array([])
        });

        this.myForm.get('startdate')?.valueChanges.subscribe((newStartDate) => {
            this.minimalDateEnd = calculateDateDifference(new Date(newStartDate), 2);
        });
        this.getEvaluationForUpdate()

    }

    saveTokensForFacility(): FormGroup {
        return this.fb.group({
            facility_id: ['', []],
            generatedEmployeeTokens: [0, []],
            generatedCustomerTokens: [0, []],
            generatedTeamTokens: [0, []],
            generatedManagementTokens: [0, []],
            facility_name: ['', []],
        })
    }


    addTokensForFacility() {
        if (this.facilities.length > 0) {
            let check = this.facilities.at(this.facilities.length - 1).value;
            if(check.facility_name && (check.generatedEmployeeTokens || check.generatedCustomerTokens || check.generatedTeamTokens || check.generatedManagementTokens )) {
                let facilityFromList = this.myForm.value?.organization?.facilities.filter(item => check.facility_name === item.name)
                let facilityId = facilityFromList[0].id
                check.facility_id = facilityId

                this.facilities.push(this.saveTokensForFacility());
            }
        }
    }

    get facilities() {
        return this.myForm.get('facilities') as FormArray;
    }

    checkStateOfUpdatingEvaluation() {
        if (this.updatingEvaluation.state == "PUBLISHED" || this.updatingEvaluation.state == "DELETED") {
            this.showErrorMessage()
            this.disableFormEditing()
            this.disableSubmitButton()
        }
    }

    onLogoDelete(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.updatingEvaluation.logo = String('')
    }

    showErrorMessage() {
        this.snackBarService.error("You can not edit this evaluation anymore")
    }

    disableFormEditing() {
        this.myForm.disable()
    }

    disableSubmitButton() {
        this.submitButtonDisabled = true
    }

    getUpdatingEvaluationSurveyById() {
        // if (this.updatingEvaluation.survey)
        //     this.evaluationService.readSurveyOfEvaluation(Number(this.updatingEvaluationId)).subscribe(response => {
        //         this.updatingEvaluationSurvey = response;
        //     })
    }

    deleteFacility(i: number) {
        this.facilities.removeAt(i)


    }

    getEvaluationForUpdate() {
        if (this.updatingEvaluationId) {
            this.evaluationService.getEvaluationById(this.updatingEvaluationId).subscribe(result => {
                console.log('REsult>>>', result)
                this.updatingEvaluation = result

                this.logoFromServer = result?.logo

                this.myForm.patchValue({
                    name: result?.name,
                    startdate: result?.startdate,
                    enddate: result?.enddate,
                    customization: result?.customization,
                    organization: {
                        id: result?.organization_id
                    },
                    // logo: result?.logo,
                    generatedEmployeeTokens: result?.generatedEmployeeTokens,
                    generatedCustomerTokens: result?.generatedCustomerTokens,
                    evaluationDescription: result?.evaluationDescription,
                    creationdate: result?.creationdate,
                    updatedate: result?.updatedate,

                    faq: result?.faq?.reduce((acc, item) => {
                        return {...acc, [item?.lang]: item?.description}
                    }, {}) as Faq,

                    facilities: result?.facilities?.map(element => ({
                        facility_id: element.facility_id,
                        generatedEmployeeTokens: element.generatedEmployeeTokens,
                        generatedCustomerTokens: element.generatedCustomerTokens,
                        generatedTeamTokens: element.generatedTeamTokens,
                        generatedManagementTokens: element.generatedManagementTokens,
                        facility_name: element.facility_name,
                    }))?.filter?.((el) => !el?.facility_id),
                })

                if (result?.customTokenTypeTitles?.length) {
                    result?.customTokenTypeTitles?.forEach((el) => {
                        this.customTokenTypeTitles.push(
                            this.fb.group({
                                tokenType: [el?.tokenType, []],
                                customTitle: [el?.customTitle, Validators.required]
                            }))
                    })
                }
                this.checkStateOfUpdatingEvaluation()
                this.getUpdatingEvaluationSurveyById()
            })

        }
    }


    createNewEvaluation(): string {
        const facilitiesToSend = this.myForm.get('facilities')?.value?.map((facility) => ({
            ...facility,
            facility_id: this.myForm.get('organization')?.value?.facilities?.find((el) => el?.name === facility?.facility_name)?.id
        }))

        const newEvaluation = {
            ...this.myForm.value,
            organization: {
                id: this.myForm.value?.organization?.id,
            },
            facilities: facilitiesToSend?.filter((el) => el?.facility_id)
        };

        if (this.updatingEvaluation) {
            newEvaluation.id = this.updatingEvaluation.id
            newEvaluation.updatedate = Date
            newEvaluation.survey = this.updatingEvaluation.survey
        }
        if (newEvaluation.name !== null)
            this.createdEvaluationTitle = newEvaluation.name
        if (newEvaluation.logo !== null)
            newEvaluation.logo = this.base64textString
        if (newEvaluation.customization === null)
            newEvaluation.customization = "assets/styles/default.css"
        if (newEvaluation.creationdate === null)
            newEvaluation.creationdate = new Date()
        newEvaluation.state = "CREATED";
        newEvaluation.faq = Object.keys(newEvaluation.faq).map((key) => ({
            lang: key,
            description: newEvaluation.faq[key]
        }))
        return newEvaluation
    }

    onLogoLoad(evt: any) {
        const file = evt.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleReaderLoaded(e) {
        this.base64textString = ('data:image/png;base64,' + btoa(e.target.result));
    }

    onTabChanged(event: any) {
        switch (event.index) {
            case 0:
                this.selectedTab.next('de');
                break;
            case 1:
                this.selectedTab.next('en');
                break;
            case 2:
                this.selectedTab.next('uk');
                break;
            case 3:
                this.selectedTab.next('ru');
                break;
            case 4:
                this.selectedTab.next('ar');
                break;
        }
    }

    openFaqPreviewDialog() {
        const dialogRef = this.dialog.open(FaqPreviewDialogComponent, {
            data: {
                message: this.myForm.value?.faq?.[this.selectedTab.value],
                buttonText: {
                    cancel: 'Done'
                }
            },
        });
    }

    onEvaluationDataSubmit() {
        const updatedEvaluation = this.createNewEvaluation();
        this.evaluationService.updateEvaluation1(String(this.updatingEvaluationId), JSON.stringify(updatedEvaluation)).subscribe(
            response => {
                this.tokensDictionary = response.tokenDictionary as any;
                this.UpdatedEvaluationId.emit(response.id)
                this.UpdatedEvaluationTitle.emit(response.name)
                this.UploadedLogo.emit(response.logo)
                this.UpdatedEvaluation.emit(response)
                this.UpdatedEvaluationFacilities.emit(this.myForm.value.facilities)


            }
        )
        if (this.updatingEvaluationSurvey) {
            this.UpdatedEvaluationSurvey.emit(this.updatingEvaluationSurvey)
        }
    }


    get customTokenTypeTitles() {
        return this.myForm.get('customTokenTypeTitles') as FormArray;
    }

    addCustomTitle() {
        const usedTokenTypes = this.myForm.value?.customTokenTypeTitles?.map((el) => el.tokenType);
        const availableTokenTypes = this.tokenTypes
            ?.filter((el) => !usedTokenTypes?.some((usedToken) => usedToken === el?.code))
        console.log("availableTokenTypes", availableTokenTypes);
        if (availableTokenTypes?.length) {
            this.customTokenTypeTitles.push(
                this.fb.group({
                    tokenType: [availableTokenTypes?.[0]?.code, []],
                    customTitle: ["", Validators.required]
                })
            )
        }
    }

    removeCustomTitle(index: number) {
        this.customTokenTypeTitles.removeAt(index)
    }

    protected readonly JSON = JSON;
}
