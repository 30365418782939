import {Component, EventEmitter, Output} from '@angular/core';
import {Evaluation} from "../../../model/Evaluation";

@Component({
  selector: 'app-ask-for-id-dialog',
  templateUrl: './ask-for-id-dialog.component.html',
  styleUrls: ['./ask-for-id-dialog.component.css']

})
export class AskForIdDialogComponent  {

  evaluationId: string
  chosenEvaluationFromList: Evaluation

  @Output() EvaluationId = new EventEmitter<string>

  onEvaluationIdSelect(){
    this.EvaluationId.emit(this.evaluationId)
  }

  receiveEvaluationFromList(event: Evaluation){
    this.chosenEvaluationFromList =  event
    this.evaluationId = String(this.chosenEvaluationFromList.id)
  }

}
