import {Component, Input, OnInit} from '@angular/core';
import {Evaluation} from "../../model/Evaluation";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {ContextService} from "../../services/context.service";
import {EvaluationService} from "../../services/evaluation.service";
import {SnackBarService} from "../../services/snack-bar.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'app-login-token',
    templateUrl: './login-token.component.html',
    styleUrls: ['login-token.component.css']
})
export class LoginTokenComponent implements OnInit {

    @Input() evaluations: Evaluation[];
    @Input() chosenEvaluationFromList: Evaluation;
    @Input() chosenEvaluation: Evaluation;

    _userAccessCode: string;

  ngOnInit() {
    this.getChosenEvaluation()
  }

  constructor(private router: Router,
              private translate: TranslateService,
              private authenticationService: AuthenticationService,
              public context: ContextService,
              private evaluationService: EvaluationService,
              private snackBarService: SnackBarService){}
  changeLoginOption(){
    this.authenticationService.loginWithEmail = true
  }

  findEvaluation(){
    if(this.chosenEvaluationFromList) {
        this.chosenEvaluation = this.chosenEvaluationFromList;
    }
    else {
        this.getChosenEvaluation();
    }
    return this.chosenEvaluation;
  }

  getChosenEvaluation(){
    if(this.context.requestedEvaluationID !== String(null)){
        this.evaluationService.getEvaluationById(this.context.requestedEvaluationID).subscribe(result =>{
          this.chosenEvaluation = result
        })
    }
  }

  loginViaToken() {
      this.authenticationService.loginViaToken(this._userAccessCode, this.findEvaluation());
  }
}
