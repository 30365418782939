import {Component} from '@angular/core';
import {NavigationService} from "../services/navigation.service";
import {AuthenticationService} from "../services/authentication.service";
import {FaqService} from "../services/faqService";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  constructor(public authenticationService: AuthenticationService, public faqService: FaqService) {
  }

  protected readonly NavigationService = NavigationService;

  onFaqClick () {
    const faq = this.faqService.faq.value;
    if (faq) {
      localStorage.setItem('faq', JSON.stringify(faq))
    }
  }
}
