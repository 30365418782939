import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../services/navigation.service";
import {JwtHelperService} from '@auth0/angular-jwt';
import {SURVEY} from "./mock-survey"
import {ContextService} from "../services/context.service";
import {EvaluationService} from "../services/evaluation.service";
import {debounceTime} from "rxjs";
import {SnackBarService} from "../services/snack-bar.service";
import {Faq, FaqService} from "../services/faqService";
import {Evaluation} from "../model/Evaluation";

@Component({
    selector: 'app-survey-representation',
    templateUrl: './survey-representation.component.html',
    styleUrls: ['./survey-representation.component.scss'],
})

export class SurveyRepresentationComponent implements OnInit, OnDestroy {

    evaluationLogo: string | null = null;
    language: string | null;
    tokenType: string;
    resultsForm: FormGroup;
    questionsArr: any[] = []
    selectedIndex = 0;
    maxPagesNumber = 0;
    defaultValue: string = "-1";
    survey = SURVEY;
    panelOpenState = false;
    evaluationTitle: string;
    evaluationDescription: string;
    evaluationId: string;

    constructor(private fb: FormBuilder,
                private authenticationService: AuthenticationService,
                private router: Router,
                private jwtHelper: JwtHelperService,
                private contextService: ContextService,
                private route: ActivatedRoute,
                private evaluationService: EvaluationService,
                private snackBarService: SnackBarService,
                private faqService: FaqService,
    ) {
    }

    ngOnInit() {
        this.evaluationId = this.getEvaluationId()
        this.getSurvey();
    }

    ngOnDestroy() {
        this.faqService.faq.next(null);
        localStorage.removeItem('currentEvaluationId')
    }

    getEvaluationId(): any {
        if (this.contextService.activeEvaluation) {
            return this.contextService.activeEvaluation.id
        } else
            return Number(this.route.snapshot.paramMap.get('id'));
    }

    getSurvey() {
        this.evaluationService.getEvaluationById(this.getEvaluationId()).subscribe((data) => {
            this.survey = data?.customSurvey;
            console.log("data custom survey>>>>", data);
            this.evaluationLogo = data?.logo || null;
            this.evaluationTitle = data?.customSurvey?.title || data?.name;
            this.evaluationDescription = data?.description || "";
            this.getLanguage();
            this.createQuestionsArray();
            this.createResultsForm();
            this.patchFormValues();
            this.getAmountOfPages();
            this.getTokenTypeFromToken();
            this.setFaq(data)
        })
    }

    setFaq(evaluation: Evaluation){
        this.faqService.faq.next(evaluation.faq?.reduce((acc, item) => {
            return {
                ...acc,
                [item?.lang]: item?.description
            }
        }, {}) as Faq)
    }

    getTokenTypeFromToken() {
        const tokenType = this.jwtHelper.decodeToken(String(localStorage.getItem('access_token')));
        if (tokenType) {
            this.tokenType = String(tokenType.type)
        }
    }

    getLanguage() {
        this.language = localStorage.getItem('lang') || 'de'
    }

    createResultsForm() {
        this.resultsForm = this.fb.group({})
        this.questionsArr.forEach(question => {
            if (question.type === "multiselect") {
                const formArray = this.fb.array([]);
                question.options.forEach(() => (
                    formArray.push(new FormControl(false))));
                this.resultsForm.addControl(question.id, formArray);
            } else if (question.type === "text") {
                const formControl = this.fb.control("");
                this.resultsForm.addControl(question.id, formControl);
            } else {
                const formControl = this.fb.control(this.defaultValue);
                this.resultsForm.addControl(question.id, formControl);
            }
        })
    }

    patchFormValues(): void {
        if (localStorage.getItem("token")) {
            this.evaluationService.getIntermediateResult(this.getEvaluationId()).subscribe(data => {
                const currentValues = JSON.parse(data) || {};
                Object.keys(currentValues).forEach(key => {
                    const formControl = this.resultsForm.get(key);
                    if (formControl) {
                        const valueToPatch = currentValues[key];
                        if (Array.isArray(valueToPatch)) {
                            this.patchFormArray(formControl as FormArray, valueToPatch)
                        } else {
                            formControl.patchValue(valueToPatch);
                        }
                    }
                });
                this.subscribeFormValues();
            }, error => {
                alert(`get intermediate result error:` + error);
            });
        }
    }

    patchFormArray(formArray: FormArray, values: any[]): void {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
        values.forEach(value => {
            formArray.push(new FormControl(value));
        });
    }

    createQuestionsArray() {
        if (this.survey) {
            for (let page of this.survey.pages) {
                this.questionsArr = this.questionsArr.concat(page.questions)
            }
        }
    }

    onCheckboxChange(checked: boolean, id: string, value: string): void {
        const formArray: FormArray = this.resultsForm.get(id) as FormArray;
        if (checked) {
            formArray.push(new FormControl(value));
        } else {
            const index = formArray.value.findIndex((val: string) => val === value);
            if (index !== -1) {
                formArray.removeAt(index);
            }
        }
    }

    onTabChange(event: any) {
        this.selectedIndex = event.index
    }

    getAmountOfPages() {
        this.maxPagesNumber = this.survey.pages.length
    }

    goToNextPage() {
        if (this.selectedIndex != this.maxPagesNumber) {
            const surveyContainerElement = document.getElementById('survey-container');
            if (surveyContainerElement){
                surveyContainerElement.scrollIntoView();
            }
            this.selectedIndex = this.selectedIndex + 1;
        }
    }

    goToPreviousPage() {
        if (this.selectedIndex > 0) {
            const surveyContainerElement = document.getElementById('survey-container');
            if (surveyContainerElement){
                surveyContainerElement.scrollIntoView();
            }
            this.selectedIndex = this.selectedIndex - 1;
        }
    }

    subscribeFormValues() {
        this.resultsForm.valueChanges.pipe(debounceTime(3000)).subscribe(value => {
                for (let key in value) {
                    if (typeof value[key] === 'number') {
                        value[key] = value[key].toString();
                    }
                }
                this.resultsForm.setValue(value, {emitEvent: false})
                this.evaluationService.saveSurveyOfEvaluationIntermediateResult(this.getEvaluationId(), value)
                    .subscribe(
                        () => {
                        },
                        error => {
                            this.snackBarService.error("Failed to update result, please try one more time")
                        }
                    )
            }
        );
    }

    completeSurvey() {
        this.evaluationService.saveSurveyOfEvaluationFinalResult(this.getEvaluationId(), this.resultsForm.value)
            .subscribe(
                () => {
                    this.authenticationService.logOut();
                    this.router.navigate(['/' + NavigationService.FINISH_SURVEYPAGE + '/' + String(this.evaluationId)])
                },
                error => {
                    this.snackBarService.error("Failed to finish survey, please try one more time")
                }
            )
    }

    protected readonly console = console;
    protected readonly Number = Number;
}

