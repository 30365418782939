



export const SURVEY = {
    "creationDate": "08.04.2024",
    "description": {
        "ru": "placeholder",
        "en": "placeholder",
        "de": "Diese Umfrage dient zur Erstellung eines Stimmungsbarometers und dient zur kontinuierlichen Bewertung interner Prozesse und Vorgaben. Dies ist ein Textbeispiel und dieses kann individuell für jede Umfrage vom Ersteller definiert werden",
        "ar": "placeholderً",
        "uk": "placeholder"
    },

    "pages": [
        {
            "value": "1",
            "title": {
                "ru": "placeholder",
                "en": "placeholder",
                "de": "Allgemein",
                "ar": "placeholder",
                "uk": "placeholder"
            },
            "questions": [
                {
                    "type": "radio",
                    "indicator": "1",
                    "id": "1",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder ",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung? (employee)",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "2",
                    "id": "2",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "bool",
                    "indicator": "3",
                    "id": "3",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "да",
                                "en": "yes",
                                "de": "Ja",
                                "ar": "نعم",
                                "uk": "Так"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "нет",
                                "en": "no",
                                "de": "Nein",
                                "ar": "لا يوجد",
                                "uk": "Ні"
                            }
                        }
                    ]
                },
            ]
        },
        {
            "value": "2",
            "title": {
                "ru": "placeholder",
                "en": "placeholder",
                "de": "Arbeitsalltag",
                "ar": "placeholder",
                "uk": "placeholder"
            },
            "questions": [
                {
                    "type": "range",
                    "indicator": "4",
                    "id": "4",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":
                        {
                            "min": 0,
                            "max": 20,
                            "thumbLabel": true,
                            "step": 1,
                            "showTicks": false
                        },
                    "options": [],

                },
                {
                    "type": "multiselect",
                    "indicator": "5",
                    "id": "5",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "6",
                    "id": "6",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "7",
                    "id": "7",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung?(employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "8",
                    "id": "8",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "9",
                    "id": "9",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "10",
                    "id": "10",
                    "tip": {
                        "ru": "placeholder",
                        "en": "placeholder",
                        "de": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
                        "ar": "placeholder",
                        "uk": "placeholder"
                    },
                    "title": {
                        "employee": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (employee)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "CUSTOMER": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (customer)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "management": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (manager)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        },
                        "team": {
                            "ru": "placeholder",
                            "en": "placeholder",
                            "de": "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (team member)?",
                            "ar": "placeholder",
                            "uk": "placeholder"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft vollumfänglich zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft überwiegend zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft eher mehr zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "placeholder",
                                "en": "placeholder",
                                "de": "Trifft nicht zu",
                                "ar": "placeholder",
                                "uk": "placeholder"
                            }
                        },
                    ]
                }
            ]
        }
    ]
}

export const SURVEY1 = {
    "creationDate": "25.08.2024",
    "title": {
        "ru": "Очень важный опрос",
        "en": "Very much important survey",
        "de": "Sehr wichtige Umfrage",
        "ar": "مسح مهم جداً",
        "uk": "Дуже важливе опитування"
    },

    "pages": [
        {
            "value": "1",
            "title": {
                "ru": "самая первая страница ",
                "en": "The very first page",
                "de": "Die erste Seite",
                "ar": "الصفحة الأولى",
                "uk": "найперша сторінка"
            },
            "questions": [
                {
                    "type": "radio",
                    "indicator": "1",
                    "id": "11",
                    "tip": {
                        "ru": "Совет: ответьте, пожалуйста, хорошо ли вы себя чувствуете сегодня",
                        "en": "Tip: please answer do you feel good today",
                        "de": "Tipp: Bitte beantworten Sie die Frage, ob Sie sich heute gut fühlen.",
                        "ar": "نصيحة: يرجى الإجابة هل تشعر بالرضا اليوم",
                        "uk": "Порада: будь ласка, дайте відповідь, чи добре ви себе почуваєте сьогодні"
                    },
                    "title": {
                        "employee": {
                            "ru": "Вы счастливы сегодня (employee)?",
                            "en": "Are you happy today (employee)?",
                            "de": "Sind Sie heute zufrieden (employee)?",
                            "ar": "هل أنت سعيد اليوم (employee)؟",
                            "uk": "Чи щасливі ви сьогодні (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Вы счастливы сегодня (customer)?",
                            "en": "Are you happy today (customer)?",
                            "de": "Sind Sie heute zufrieden (customer)?",
                            "ar": "هل أنت سعيد اليوم (customere)؟",
                            "uk": "Чи щасливі ви сьогодні (customer)?"
                        },
                        "management": {
                            "ru": "Вы счастливы сегодня (manager)?",
                            "en": "Are you happy today (manager)?",
                            "de": "Sind Sie heute zufrieden (manager)?",
                            "ar": "هل أنت سعيد اليوم (manager)؟",
                            "uk": "Чи щасливі ви сьогодні (manager)?"
                        },
                        "team": {
                            "ru": "Вы счастливы сегодня (team member)?",
                            "en": "Are you happy today (team member)?",
                            "de": "Sind Sie heute zufrieden (team member)?",
                            "ar": "هل أنت سعيد اليوم؟",
                            "uk": "Чи щасливі ви сьогодні (team member)?"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "Да, жизнь прекрасна",
                                "en": "yes, life is wonderful",
                                "de": "ja, das Leben ist schön",
                                "ar": "نعم، الحياة رائعة",
                                "uk": "так, життя прекрасне"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "не уверен",
                                "en": "not sure",
                                "de": "nicht sicher",
                                "ar": "غير متأكد",
                                "uk": "Не знаю"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "Нет, я чувствую себя ужасно",
                                "en": "no, i feel terrible",
                                "de": "Nein, ich fühle mich schrecklich.",
                                "ar": "لا، أشعر بالسوء",
                                "uk": "Ні, я почуваюся жахливо"
                            }
                        }
                    ]
                },
                {
                    "type": "radio",
                    "indicator": "2",
                    "id": "12",
                    "tip": {
                        "ru": "Совет: пожалуйста, выберите что-нибудь",
                        "en": "Tip: please choose something",
                        "de": "Tipp: Bitte wählen Sie etwas",
                        "ar": "نصيحة: يرجى اختيار شيء",
                        "uk": "Порада: будь ласка, оберіть щось"
                    },
                    "title": {
                        "employee": {
                            "ru": "Как дела (employee)?",
                            "en": "How are you (employee)?",
                            "de": "Wie geht's Ihnen (employee)?",
                            "ar": "كيف حالك (employee)؟",
                            "uk": "Як поживаєте (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Как дела (customer)?",
                            "en": "How are you (customer)?",
                            "de": "Wie geht's Ihnen (customer)?",
                            "ar": "كيف حالك (customer)؟",
                            "uk": "Як поживаєте (customer)?"
                        },
                        "management": {
                            "ru": "Как дела (manager)?",
                            "en": "How are you (manager)?",
                            "de": "Wie geht's Ihnen (manager)?",
                            "ar": "كيف حالك (manager)؟",
                            "uk": "Як поживаєте (manager)?"
                        },
                        "team": {
                            "ru": "Как дела (team member)?",
                            "en": "How are you (team member)?",
                            "de": "Wie geht's Ihnen (team member)?",
                            "ar": "كيف حالك (team member)؟",
                            "uk": "Як поживаєте (team member)?"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "Все прекрасно!",
                                "en": "All is perfect!",
                                "de": "Alles ist perfekt!",
                                "ar": "كل شيء على ما يرام!",
                                "uk": "Все чудово!"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "Я в порядке",
                                "en": "I'm okay",
                                "de": "Mir geht's gut.",
                                "ar": "أنا بخير",
                                "uk": "Все гаразд"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "Не так уж и хорошо",
                                "en": "Not so good",
                                "de": "Nicht so gut",
                                "ar": "ليس كذلك",
                                "uk": "Не дуже"
                            }
                        }
                    ]
                },
                {
                    "type": "bool",
                    "indicator": "3",
                    "id": "13",
                    "tip": {
                        "ru": "Подсказка: пожалуйста, ответьте да или нет",
                        "en": "Tip: please answer yes or no",
                        "de": "Tipp: bitte mit ja oder nein beantworten",
                        "ar": "نصيحة: يرجى الإجابة بنعم أو لا",
                        "uk": "Порада: будь ласка, відповідайте так або ні"
                    },
                    "title": {
                        "employee": {
                            "ru": "Хочешь печеньку (employee)?",
                            "en": "Do you want a cookie (employee)?",
                            "de": "Willst du einen Keks (employee)?",
                            "ar": "هل تريد كعكة (employee)؟",
                            "uk": "Хочеш печеньку (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Хочешь печеньку (customer)?",
                            "en": "Do you want a cookie (customer)?",
                            "de": "Willst du einen Keks (customer)?",
                            "ar": "هل تريد كعكة (customer)؟",
                            "uk": "Хочеш печеньку (customer)?"
                        },
                        "management": {
                            "ru": "Хочешь печеньку (manager)?",
                            "en": "Do you want a cookie (manager)?",
                            "de": "Willst du einen Keks (manager)?",
                            "ar": "هل تريد كعكة (manager)؟",
                            "uk": "Хочеш печеньку (manager)?"
                        },
                        "team": {
                            "ru": "Хочешь печеньку (team member)?",
                            "en": "Do you want a cookie (team member)?",
                            "de": "Willst du einen Keks (team member)?",
                            "ar": "هل تريد كعكة (team member)؟",
                            "uk": "Хочеш печеньку (team member)?"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "да",
                                "en": "yes",
                                "de": "Ja",
                                "ar": "نعم",
                                "uk": "Так"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "нет",
                                "en": "no",
                                "de": "Nein",
                                "ar": "لا يوجد",
                                "uk": "Ні"
                            }
                        }
                    ]
                },
                {
                    "type": "range",
                    "indicator": "4",
                    "id": "14",
                    "tip": {
                        "ru": "Совет: выберите число с помощью ползунка",
                        "en": "Tip: please choose the number using slider",
                        "de": "Tipp: Bitte wählen Sie die Nummer mit dem Slider",
                        "ar": "نصيحة: يرجى اختيار الرقم باستخدام شريط التمرير",
                        "uk": "Порада: будь ласка, оберіть число за допомогою повзунка"
                    },
                    "title": {
                        "employee": {
                            "ru": "Выберите число от 0 до 10 (employee)?",
                            "en": "Choose the number from 0 to 10 (employee)?",
                            "de": "Wählen Sie eine Zahl zwischen 0 und 10 (employee)?",
                            "ar": "اختر الرقم من 0 إلى 10 (employee)?",
                            "uk": "Виберіть число від 0 до 10 (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Выберите число от 0 до 10 (customer)?",
                            "en": "Choose the number from 0 to 10 (customer)?",
                            "de": "Wählen Sie eine Zahl zwischen 0 und 10 (customer)?",
                            "ar": "اختر الرقم من 0 إلى 10 (customer)?",
                            "uk": "Виберіть число від 0 до 10 (customer)?"
                        },
                        "management": {
                            "ru": "Выберите число от 0 до 10 (manager)?",
                            "en": "Choose the number from 0 to 10 (manager)?",
                            "de": "Wählen Sie eine Zahl zwischen 0 und 10 (manager)?",
                            "ar": "اختر الرقم من 0 إلى 10 (manager)?",
                            "uk": "Виберіть число від 0 до 10 (manager)?"
                        },
                        "team": {
                            "ru": "Выберите число от 0 до 10 (team member)?",
                            "en": "Choose the number from 0 to 10 (team member)?",
                            "de": "Wählen Sie eine Zahl zwischen 0 und 10 (team member)?",
                            "ar": "اختر الرقم من 0 إلى 10 (team member)?",
                            "uk": "Виберіть число від 0 до 10 (team member)?"
                        }
                    },
                    "settings":
                        {
                            "min": 0,
                            "max": 20,
                            "thumbLabel": true,
                            "step": 1,
                            "showTicks": false
                        },
                    "options": [],

                },
                {
                    "type": "multiselect",
                    "indicator": "5",
                    "id": "15",
                    "tip": {
                        "ru": "Совет: вы можете выбрать несколько вариантов",
                        "en": "Tip: you can choose several options",
                        "de": "Tipp: Sie können mehrere Optionen wählen",
                        "ar": "نصيحة: يمكنك اختيار عدة خيارات",
                        "uk": "Порада: ви можете вибрати кілька варіантів"
                    },
                    "title": {
                        "employee": {
                            "ru": "Какие фрукты вам нравятся (employee)?",
                            "en": "Which fruits do you like (employee)?",
                            "de": "Welches Obst mögen Sie (employee)?",
                            "ar": "ما الفاكهة التي تحبها (employee)؟",
                            "uk": "Які фрукти вам подобаються (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Какие фрукты вам нравятся (customer)?",
                            "en": "Which fruits do you like (customer)?",
                            "de": "Welches Obst mögen Sie?(customer)",
                            "ar": "ما الفاكهة التي تحبها (customer)؟",
                            "uk": "Які фрукти вам подобаються (customer)?"
                        },
                        "management": {
                            "ru": "Какие фрукты вам нравятся (manager)?",
                            "en": "Which fruits do you like (manager)?",
                            "de": "Welches Obst mögen Sie?(customer)",
                            "ar": "ما الفاكهة التي تحبها (manager)؟",
                            "uk": "Які фрукти вам подобаються (manager)?"
                        },
                        "team": {
                            "ru": "Какие фрукты вам нравятся (team member)?",
                            "en": "Which fruits do you like (team member)?",
                            "de": "Welches Obst mögen Sie?(team member)",
                            "ar": "ما الفاكهة التي تحبها (team member)؟",
                            "uk": "Які фрукти вам подобаються (team member)?"
                        }
                    },
                    "settings": {
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "Яблоко",
                                "en": "Apple",
                                "de": "Apfel",
                                "ar": "تفاحة",
                                "uk": "Яблуко"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "Банан",
                                "en": "Banana",
                                "de": "Banane",
                                "ar": "الموز",
                                "uk": "Банан"
                            }
                        },
                        {
                            "value": "answer3",
                            "title": {
                                "ru": "Киви",
                                "en": "Kiwi",
                                "de": "Kiwi",
                                "ar": "الكيوي",
                                "uk": "Ківі"
                            }
                        },
                        {
                            "value": "answer4",
                            "title": {
                                "ru": "Апельсин",
                                "en": "Orange",
                                "de": "Orange",
                                "ar": "برتقالي",
                                "uk": "Апельсин"
                            }
                        },
                        {
                            "value": "answer5",
                            "title": {
                                "ru": "Лимон",
                                "en": "Lemon",
                                "de": "Zitrone",
                                "ar": "ليمون",
                                "uk": "Лимон"
                            }
                        }
                    ]
                },
            ]
        },
        {
            "value": "2",
            "title": {
                "ru": "вторая страница",
                "en": "The very second page",
                "de": "Die zweite Seite",
                "ar": "الصفحة الثانية",
                "uk": "вже друга сторінка"
            },
            "questions": [
                {
                    "type": "radio",
                    "indicator": "6",
                    "id": "16",
                    "tip": {
                        "ru": "Совет: пожалуйста, выберите что-нибудь",
                        "en": "Tip: please choose something",
                        "de": "Tipp: Bitte wählen Sie etwas",
                        "ar": "نصيحة: يرجى اختيار شيء",
                        "uk": "Порада: будь ласка, оберіть щось"
                    },
                    "title": {
                        "employee": {
                            "ru": "Вы были счастливы вчера (employee)?",
                            "en": "Were you happy yesterday (employee)?",
                            "de": "Warst du gestern glücklich (employee)?",
                            "ar": "هل كنت سعيداً بالأمس (employee)؟",
                            "uk": "Чи були ви щасливі вчора (employee)?"
                        },
                        "CUSTOMER": {
                            "ru": "Вы были счастливы вчера (customer)?",
                            "en": "Were you happy yesterday (customer)?",
                            "de": "Warst du gestern glücklich (customer)?",
                            "ar": "هل كنت سعيداً بالأمس (customer)؟",
                            "uk": "Чи були ви щасливі вчора (customer)?"
                        },
                        "management": {
                            "ru": "Вы были счастливы вчера (manager)?",
                            "en": "Were you happy yesterday (manager)?",
                            "de": "Warst du gestern glücklich (manager)?",
                            "ar": "هل كنت سعيداً بالأمس (manager)؟",
                            "uk": "Чи були ви щасливі вчора (manager)?"
                        },
                        "team": {
                            "ru": "Вы были счастливы вчера (team member)?",
                            "en": "Were you happy yesterday (team member)?",
                            "de": "Warst du gestern glücklich (team member)?",
                            "ar": "هل كنت سعيداً بالأمس (team member)؟",
                            "uk": "Чи були ви щасливі вчора (team member)?"
                        }
                    },
                    "settings":{
                        "min": null,
                        "max": null,
                        "thumbLabel": null,
                        "step": null,
                        "showTicks": null
                    },
                    "options": [
                        {
                            "value": "answer1",
                            "title": {
                                "ru": "Да, все было прекрасно",
                                "en": "yes, yesterday was wonderful",
                                "de": "ja, gestern war wunderbar",
                                "ar": "نعم، كان يوم أمس رائعاً",
                                "uk": "так, вчорашній день був чудовим"
                            }
                        },
                        {
                            "value": "answer2",
                            "title": {
                                "ru": "Нет, это был ужасный день",
                                "en": "no, it was terrible day",
                                "de": "nein, es war ein schrecklicher Tag",
                                "ar": "لا، لقد كان يوماً فظيعاً",
                                "uk": "ні, це був жахливий день"
                            }
                        }
                    ]
                }
            ]
        }
    ]
}