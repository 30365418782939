import {Component, Input} from '@angular/core';
import {Evaluation} from "../../model/Evaluation";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {ContextService} from "../../services/context.service";
import {NavigationService} from "../../services/navigation.service";
import moment from "moment/moment";
import {SnackBarService} from "../../services/snack-bar.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.css']
})
export class LoginEmailComponent {

  @Input() email: string;
  @Input() password: string;
  @Input() evaluations: Evaluation[];
  @Input() chosenEvaluationFromList: Evaluation;

  chosenEvaluation: Evaluation;

  constructor(private router: Router,
              private translate: TranslateService,
              public authenticationService: AuthenticationService,
              private context: ContextService,
              private snackBarService: SnackBarService) {}

  changeLoginOption(){
    this.authenticationService.loginWithEmail = false
  }

  // findEvaluationById(): Evaluation{
  //   if(this.chosenEvaluationFromList !== undefined){
  //     return  this.chosenEvaluation = this.chosenEvaluationFromList
  //   }
  //   return this.chosenEvaluation = this.evaluations.filter(evaluation =>evaluation.id === Number(this.context.requestedEvaluationID))[0]
  // }


  loginViaEmail() {

    try {
      this.authenticationService.loginViaEmail(this.email, this.password).subscribe(data => {
        this.authenticationService.isAdmin.next(true);
        this.authenticationService.setToken(data.access_token)
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem("expires_in", JSON.stringify(data.expires_in.valueOf()) );
        localStorage.setItem("expires_at", moment(moment.now()).add(data.expires_in, 'seconds').toLocaleString());
        this.context.activeEvaluation = this.chosenEvaluation;
        if(localStorage.getItem("token")){
          this.router.navigate([NavigationService.ADMINPAGE]).then()
        }
      });
    }
    catch (Error){
      // alert ("error login");
      this.snackBarService.error(this.translate.instant('loginFailedAdmin'))
    }
  }

}
