<div [formGroup]="formGroup" >
    <mat-form-field class="main" appearance="outline">
        <mat-label>{{ 'organizationId' | translate}}</mat-label>
        <input type="text" matInput [formControl]="formControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let organization of filteredOrganizations | async" [value]="organization">
                {{organization.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
