import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../services/navigation.service";


@Component({
    selector: 'app-faq',
    templateUrl: './finish-survey.component.html',
    styleUrls: ['./finish-survey.component.css'],
})
export class FinishSurveyComponent implements OnInit, OnDestroy {
    counter: number = 6;
    intervalId: any;
    evaluationId: string;

    constructor(private router: Router,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.evaluationId = this.getEvaluationId()
        // this.intervalId = setInterval(() => {
        //     this.counter--;
        //     if (this.counter === 0) {
        //         this.router.navigate( [NavigationService.LOGINPAGE], {
        //             queryParams: {
        //                 id: this.evaluationId
        //             },
        //             queryParamsHandling: 'merge',
        //         });
        //     }
        // }, 1000);
    }

    getEvaluationId(): any {
        return this.route.snapshot.paramMap.get('evaluationId');
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);
    }
}
