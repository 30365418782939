import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {

  searchTerm = ''

  @Output() SearchTerm = new EventEmitter<string>
  search(searchTerm: string): string {
    this.searchTerm = searchTerm.toLowerCase()
    this.SearchTerm.emit(this.searchTerm)
   return  this.searchTerm = searchTerm.toLowerCase();
  }
}
