<div *ngFor="let item of facilitiesArr" class="container">
    <div class="card-container">
        <span class="" >{{item.facilityName}}</span>
        <span class="" >{{item.numberOfClients}}</span>
        <span class="" >{{item.numberOfCustomers}}</span>
        <span class="">{{item.numberOfTeamMembers}}</span>
        <span class="">{{item.numberOfParents}}</span>
    </div>
<!--    <form [formGroup]="formTokens">-->
<!--        <div class="inline_elements">-->
<!--            <mat-form-field class="one-fifth-width" appearance="outline">-->
<!--                <mat-label>Facility</mat-label>-->
<!--                <mat-select formControlName="facilityId">-->
<!--                    <input formControlName="facilityId" matInput placeholder="{{'nameOfFacility' | translate}}">-->
<!--                    <mat-option *ngFor="let facility of organization.facilities"-->
<!--                                [value]="facility.id">{{facility.name}}</mat-option>-->
<!--                </mat-select>-->
<!--            </mat-form-field>-->
<!--            <mat-form-field appearance="outline" class="one-fifth-width">-->
<!--                <mat-label>Number of clients:</mat-label>-->
<!--                <input formControlName="numberOfClients" matInput placeholder="{{'nameOfFacility' | translate}}">-->
<!--            </mat-form-field>-->
<!--            <mat-form-field appearance="outline" class="one-fifth-width">-->
<!--                <mat-label>Number of customers:</mat-label>-->
<!--                <input formControlName="numberOfCustomers" matInput placeholder="{{'nameOfFacility' | translate}}">-->
<!--            </mat-form-field>-->
<!--            <mat-form-field appearance="outline" class="one-fifth-width">-->
<!--                <mat-label>Number of team members:</mat-label>-->
<!--                <input formControlName="numberOfTeamMembers" matInput-->
<!--                       placeholder="{{'nameOfFacility' | translate}}">-->
<!--            </mat-form-field>-->
<!--            <mat-form-field appearance="outline" class="one-fifth-width">-->
<!--                <mat-label>Number of parents:</mat-label>-->
<!--                <input formControlName="numberOfParents" matInput placeholder="{{'nameOfFacility' | translate}}">-->
<!--            </mat-form-field>-->
<!--            <mat-button-toggle (click)="addFacilityToArray()">Add Facility</mat-button-toggle>-->
<!--        </div>-->
<!--    </form>-->
</div>
<form [formGroup]="facility">
    <div class="inline_elements" formGroupName="facility">
        <mat-form-field class="one-fifth-width" appearance="outline">
            <mat-label>Facility</mat-label>
            <mat-select formControlName="facilityName">
                <input formControlName="facilityName" matInput placeholder="{{'nameOfFacility' | translate}}">
                <mat-option *ngFor="let facility of organization.facilities"
                            [value]="facility.name">{{facility.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="one-fifth-width">
            <mat-label>Number of clients:</mat-label>
            <input formControlName="numberOfClients" matInput placeholder="{{'nameOfFacility' | translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline" class="one-fifth-width">
            <mat-label>Number of customers:</mat-label>
            <input formControlName="numberOfCustomers" matInput placeholder="{{'nameOfFacility' | translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline" class="one-fifth-width">
            <mat-label>Number of team members:</mat-label>
            <input formControlName="numberOfTeamMembers" matInput
                   placeholder="{{'nameOfFacility' | translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline" class="one-fifth-width">
            <mat-label>Number of parents:</mat-label>
            <input formControlName="numberOfParents" matInput placeholder="{{'nameOfFacility' | translate}}">
        </mat-form-field>
        <mat-button-toggle (click)="addFacilityToArray()">Add Facility</mat-button-toggle>
    </div>
</form>

<!--<mat-button-toggle (click)="addFacilityToArray()">Add facility</mat-button-toggle>-->
