import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-warning-modal-dialog',
  templateUrl: './warning-modal-dialog.component.html',
  styleUrls: ['./warning-modal-dialog.component.css']
})
export class WarningModalDialogComponent {

  modalMessage: string
  modalWarning: string
  modalButtonName: string
  onModalClick: ()=>void


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<WarningModalDialogComponent>){
    this.modalMessage = data.message
    this.modalWarning = data.warning
    this.modalButtonName = data.buttonName
    this.onModalClick = data.onModalClick

  }
  actionFunction() {
    this.onModalClick()
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();

  }


}
