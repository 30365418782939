import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ContextService} from "./context.service";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  countryServiceURL = "countryModelService"

  constructor(private http: HttpClient,
              private context: ContextService) {
  }

  public getCountryModel(): Observable<any> {
    return this.http.get<any>(this.context._configurationEnvironment.service_engine_base_url + this.countryServiceURL, {
      headers: {'Content-Type': 'application/json'}
    });
  }


}