<app-sidebar>
    <div class="container">
        <h1>{{'facilityCreator' | translate}}</h1>
        <app-geo-chooser *ngIf="!this.facilityForUpdate" (CommunityId)="receiveCommunityId($event)"></app-geo-chooser>
        <form *ngIf="this.facilityForUpdate || communityId" class="form" (ngSubmit)="this.handleFacilitySubmit()"
              [formGroup]="facilityForm">
            <div class="sub-container">
                <h2>{{'dataFacility' | translate}}</h2>
                <div class="inline-fields">
                    <div class="half-width">
                        <label>{{'nameOfFacility' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="name" matInput placeholder="{{'nameOfFacility' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <label>{{'abbreviation' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="shortname" matInput placeholder="{{'abbreviation' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
                <label>{{'address' | translate}}</label>
                <mat-form-field class="full-width" appearance="outline">
                    <input formControlName="address" matInput placeholder="{{'address' | translate}}">
                </mat-form-field>
                <div class="inline-fields">
                    <div class="one-fourth-width">
                        <label>{{'zipCode' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="postcode" matInput placeholder="{{'zipCode' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="three-fourth-width">
                        <label>{{'city' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="city" matInput placeholder="{{'city' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="sub-container">
                <h2>{{'contactPerson' | translate}}</h2>
                <div class="inline-fields">
                    <div class="half-width">
                        <label>{{'firstName' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="contactpersonfirstname" matInput
                                   placeholder="{{'firstName' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <label>{{'lastName' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="contactpersonfamilyname" matInput
                                   placeholder="{{'lastName' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
                <div class="inline-fields">
                    <div class="half-width">
                        <label>{{'phoneNumber' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="phonenumber" matInput placeholder="{{'phoneNumber' | translate}}">
                            <mat-icon matPrefix> phone</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <label>{{'email' | translate}}</label>
                        <mat-form-field class="mat-form-field" appearance="outline">
                            <input formControlName="email" matInput placeholder="{{'email' | translate}}">
                            <mat-icon matPrefix>alternate_email</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class="button-container">
                    <button mat-raised-button color="primary" type="submit" [class.disabled]="facilityForm.invalid"
                            [disabled]="facilityForm.invalid"> {{'submit' | translate}} </button>
                </div>
            </div>
        </form>
    </div>
</app-sidebar>

