import {Injectable} from "@angular/core";
import {DEFAULT_LOGO} from "../common/defaultLogo";
import {environment} from "../../environments/environment";
import pdfMake from "pdfmake/build/pdfmake";
import html2canvas from "html2canvas";
import {TranslateService} from "@ngx-translate/core";
import {Evaluation} from "../model/Evaluation";
import {PlotlyService} from "angular-plotly.js";

type PdfDataTokens = {
    logo: string;
    evaluationDescription: string;
    tokens: Array<{ code: string; type: string; }>;
    evaluationId: string;
    facilityName: string;
    tokenDictionary?: Record<string, string>;
};

type PdfPayloadResult = {
    togglePdfLoading: (value: boolean) => void;
    organizationTitle?: string;
    currentEvaluation: Evaluation | null;
    graphDataAll: any[];
    evaluationSummary: Record<string, string>;
    categories: any[];
    dataSourceGeneral?: any;
    columnsGeneral?: any;
    dataSourceOpenAnswers?: any;
    columnsOpenAnswers?: any;
}

@Injectable({
    providedIn: 'root',
})
export class PdfService {
    constructor(
        private translate: TranslateService,
        private plotlyService: PlotlyService
    ) {
    }

    public generateTokensPDF(data: PdfDataTokens) {
        //creating a pdf instance
        let docDefinition: any = {
            content: [],
            images: {
                logo: this.getDefaultLogoForDoc(data?.logo)
            },
            styles: {
                sectionHeader: {
                    bold: true,
                    decoration: 'underline',
                    fontSize: 14,
                    margin: [0, 15, 0, 15]
                }
            }
        };
        //Filling a pdf instance with content (please check documentation: https://pdfmake.github.io/docs/0.1/)
        data.tokens.forEach((item, index) => {
            let tokenTitle = `${item.type === "EMPLOYEE" ? "MITARBEITER" : "KUNDEN"} - Token`;
            if (data?.tokenDictionary) {
                tokenTitle = data?.tokenDictionary?.[item.type];
            }
            docDefinition.content.push(...[
                {
                    columns: [
                        [
                            {
                                text: `${data.facilityName}`,
                                margin: [0, 5],
                                fontSize: 15,
                                alignment: 'left',
                                bold: true,
                            },
                        ],
                        [
                            {
                                text: tokenTitle,
                                margin: [0, 5],
                                fontSize: 15,
                                alignment: 'right',
                                bold: true,
                            },
                        ]
                    ],
                    pageBreak: index === 0 ? 'none' : 'before'
                },
                // this.getLogoString(),
                {
                    text: '„Umfrage zur Umsetzung alltagsintegrierter Sprachbildung/-förderung in den Kindertageseinrichtungen des LK Goslar“',
                    fontSize: 10,
                    alignment: 'center',
                    color: '#FFA500',
                    margin: [0, 7],
                    bold: true
                },
                {
                    text: 'Liebe Eltern, Pädagogische Kräfte, Leitungen und Träger',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 7],
                    bold: true
                },
                {
                    text: 'Herzlich willkommen zur Umfrage Ihrer Kita:',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 7],
                },
                {
                    text: 'Mit unserer Umfrage möchten wir Ihre persönliche Einschätzung erhalten: Wie zufrieden sind Sie? Was kann verbessert werden? Wie erleben Sie die Umsetzung der alltagsintegrierten Sprachbildung und Sprachförderung in Ihrer Einrichtung?',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 7],
                    bold: true
                },
                {
                    text: 'Die Ergebnisse dienen der Sicherung und Weiterentwicklung der Qualität in den Kitas und den Horten.',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 7],
                },
                {
                    text: 'Die Auswertung erfolgt spezifisch für jede Kindertagesstätte. Durch Ihre Teilnahme haben Sie die Möglichkeit, die pädagogische Arbeit Ihrer Einrichtung zu unterstützen. ',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    text: 'Es gibt keine „richtigen“ oder „falschen“ Antworten auf die Fragen. Antworten Sie auf die Fragen so, wie es Ihrer Meinung am ehesten entspricht.',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    text: 'Hinweise zur Nutzung finden Sie in den FAQ auf der Website. ',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    columns: [
                        [{
                            text: 'Zeitraum:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: '01.01.2025. bis 28.02.25',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },]
                    ]
                },
                {
                    columns: [
                        [{
                            text: 'Art der Umfrage:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: 'digital, per Handy, PC, Laptop oder Tablet',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                        },]
                    ]
                },
                {
                    columns: [
                        [{
                            text: 'Zugang:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: 'mit zufallsgeneriertem Zugangscode, zur Sicherung der Anonymität',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                        },]
                    ]
                },
                {
                    columns: [
                        [{
                            text: 'Bearbeitung:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: 'jederzeit, auch nach Unterbrechung fortsetzbar',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                        },]
                    ]
                },
                {
                    columns: [
                        [{
                            text: 'Umfang:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: 'ca. 10 bis 20 Min.',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                        },]
                    ]
                },
                {
                    columns: [
                        [{
                            text: 'Ergebnisse:',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                            bold: true,
                        },],
                        [{
                            text: 'automatische Aufbereitung, einrichtungsspezifisch.',
                            margin: [0, 2],
                            fontSize: 10,
                            alignment: 'left',
                        },]
                    ]
                },
                {
                    columns: [
                        [
                            {
                                text: 'Wir brauchen Ihre Unterstützung!',
                                margin: [0, 2],
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                            },
                            {
                                text: 'Machen Sie mit – beteiligen Sie sich an der Umfrage per: QR-Cod',
                                margin: [0, 10],
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                            },
                            {
                                text: 'Wie geht’s?',
                                margin: [0, 5],
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                            },
                            {
                                text: 'QR-Code: Scannen Sie den beigefügten QR-Code und Sie gelangen direkt auf die Umfrage.',
                                margin: [0, 1],
                                fontSize: 10,
                                alignment: 'left',
                            }
                        ],
                        [{
                            qr: `${environment?.app_url}?id=${data.evaluationId}&token=${item?.code}`,
                            alignment: 'right',
                            fit: '100'
                        }],
                    ],
                    margin: [0, 10]
                },




                {
                    text: 'Diese Umfrage wurde erarbeitet durch die AG alltagsintegrierte Sprachbildung /-förderung des LK Goslar. Danke für Ihre Unterstützung! ',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },

                {
                    text: 'Oder:',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#f30404',
                    margin: [0, 2],
                },
                {
                    text: 'Website: Gehen Sie über den Browser ins Internet auf die Seite',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        align: 'right',

                        body: [
                            [
                                {
                                    text: `${environment?.app_url}?id=${data.evaluationId}`,
                                    fontSize: 13,
                                    alignment: 'left',
                                    color: '#000',
                                    margin: [0, 5],
                                    bold: true
                                },
                            ]
                        ]
                    }
                },
                {
                    columns: [
                        [{
                            text: 'Geben Sie Ihren Zugangscode ein und Sie gelangen direkt auf die Umfrage.',
                            fontSize: 13,
                            alignment: 'left',
                            color: '#000',
                            bold: true
                        },],
                        [
                            {
                                table: {
                                    headerRows: 1,
                                    widths: ["*"],
                                    align: 'right',
                                    body: [
                                        [{text: item.code, bold: true, align: 'right'}]
                                    ]
                                },
                                margin: [0, 10, 0, 0]
                            }
                        ]
                    ]
                },
                {
                    text: 'Wir freuen uns auf Ihre Teilnahme und grüßen Sie herzlich.',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    text: 'Adrian Einecke',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    text: 'Bereichsleiter',
                    fontSize: 8,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 3],
                },
                {
                    text: 'Diese Umfrage wurde erarbeitet durch die AG alltagsintegrierte Sprachbildung /-förderung des LK Goslar. Danke für Ihre Unterstützung! Tel. 05321/ 76-710',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                {
                    text: 'Fr. Jaschinsky (Start gGmbH), Tel. +49 151/ 187 900 20 ',
                    fontSize: 10,
                    alignment: 'left',
                    color: '#000',
                    margin: [0, 5],
                },
                // {
                //     columns: [
                //         [{
                //             stack: [
                //                 // second column consists of paragraphs
                //                 {text: 'Wir brauchen Inre Unterstützung!', alignment: "left"},
                //                 {text: 'Machen Sie mit - beteiligen Sie sich an der Umfrage unter:', alignment: "left"},
                //                 {
                //                     text: `${environment?.app_url}?id=${data.evaluationId}`,
                //                     margin: [0, 10],
                //                     alignment: 'left',
                //                     bold: true
                //                 },
                //                 {
                //                     text: '(Sie können den Link nutzen oder den QR code mit ihrem Handy scannen)',
                //                     alignment: "left",
                //                     bold: false,
                //                     fontSize: 10
                //                 },
                //             ],
                //         }],
                //         [{
                //             qr: `${environment?.app_url}?id=${data.evaluationId}&token=${item?.code}`,
                //             fit: '100'
                //         }],
                //     ],
                //     alignment: 'center',
                //     margin: [0, 20],
                //     width: '100%'
                // },
                // {
                //     fontSize: 15,
                //     text: data.evaluationDescription,
                //     margin: [0, 5],
                //     bold: true
                // },
                // {
                //     text: `Zugangscode für Ihre Befragung (${tokenTitle}):`,
                //     margin: [0, 10],
                //     bold: true
                // },
                // {
                //     table: {
                //         headerRows: 1,
                //         widths: ["*"],
                //         align: 'right',
                //
                //         body: [
                //             [{text: item.code, bold: true, align: 'right'}]
                //         ]
                //     }
                // },
                // {text: '', margin: [0, 100]},
            ])
        })
        //@ts-ignore
        pdfMake.createPdf(docDefinition).open();
    }

    public async generateResultPDF(payload: PdfPayloadResult) {
        console.log("payload>>>", payload)
        payload.togglePdfLoading(true);
        let docDefinition: any = {
            content: [
                // this.getLogoString(),
                this.getGeneralInfoString(this.translate.instant('evaluationResultsFor'), payload.currentEvaluation?.name || ''),
                this.getGeneralInfoString(`${this.translate.instant('nameOfOrganization')}: `, payload.organizationTitle || ''),
                this.getSummaryString(`${this.translate.instant('startDateSummary')} ${payload.currentEvaluation?.startdate}`),
                this.getSummaryString(`${this.translate.instant('endDateSummary')} ${payload.currentEvaluation?.enddate}`),
                // this.getSummaryString(`${this.translate.instant('tokenIssuesCustomers')} ${payload.currentEvaluation?.generatedCustomerTokens}`),
                // this.getSummaryString(`${this.translate.instant('tokenIssuedEmployees')} ${payload.currentEvaluation?.generatedEmployeeTokens}`),
                this.getSummaryString(`${this.translate.instant('deliveredTokens')} ${payload.evaluationSummary?.["deliveredTokens"]}`),
                this.getSummaryString(`${this.translate.instant('finalResult')} ${payload.evaluationSummary?.["finalResults"]}`),
                this.getSummaryString(`${this.translate.instant('intermediateResults')} ${payload.evaluationSummary?.["intermediateResults"]}`),
                this.getSummaryString(`${this.translate.instant('unusedTokens')} ${payload.evaluationSummary?.["unusedTokens"]}`),
                this.getSummaryString(`${this.translate.instant('usedTokens')} ${payload.evaluationSummary?.["usedTokens"]}`, true),
            ],
            images: {
                logo: this.getDefaultLogoForDoc(payload?.currentEvaluation?.logo || DEFAULT_LOGO)
            },
            styles: {
                sectionHeader: {
                    bold: true,
                    decoration: 'underline',
                    fontSize: 14,
                    margin: [0, 15, 0, 15]
                }
            }
        };

        setTimeout(async () => {
            try {
                for (const category of payload?.categories) {
                    const index = payload?.categories.indexOf(category);
                    docDefinition.content.push({
                        text: category?.title,
                        fontSize: 16,
                        alignment: 'center',
                        color: '#000',
                        margin: [0, 20],
                        pageBreak: index === 0 ? "none" :"before"
                    })

                    const graphDataAllToRender = category?.graphDataAll?.filter((el) => el?.type !== 'table')
                    for (let i = 0; i < graphDataAllToRender?.length; i += 2) {
                        // const graphItemData1 = await html2canvas(document.getElementById(`plot${i}`) as HTMLElement);
                        const reportGraph = this.plotlyService.getInstanceByDivId(`plot${category?.id}${i}`);
                        const plotly = await this.plotlyService.getPlotly();
                        const graphItemData1 = await plotly.toImage(reportGraph, {
                            format: 'png',
                            width: 500,
                            height: 300,
                            filename: 'test.png'
                        }, {format:'png',height:800,width:800});
                        docDefinition.images[`plot${category?.id}${i}`] = graphItemData1;
                        docDefinition.content.push({image: `plot${category?.id}${i}`, alignment: 'center', width: 500, margin: [0, 20]})
                        if (i + 1 < graphDataAllToRender?.length) {

                            // const graphItemData2 = await html2canvas(document.getElementById(`plot${i + 1}`) as HTMLElement);
                            const reportGraph = this.plotlyService.getInstanceByDivId(`plot${category?.id}${i + 1}`);
                            const plotly = await this.plotlyService.getPlotly();
                            const graphItemData2 = await plotly.toImage(reportGraph, {
                                format: 'png',
                                width: 500,
                                height: 300,
                                filename: 'test.png'
                            }, {format:'png',height:800,width:800});
                            docDefinition.images[`plot${category?.id}${i + 1}`] = graphItemData2;
                            docDefinition.content.push({
                                image: `plot${category?.id}${i + 1}`,
                                width: 500,
                                alignment: 'center',
                                margin: [0, 20],
                                pageBreak: i + 2 < graphDataAllToRender?.length ? 'after' : 'none'
                            })
                        }
                    }
                }

                const bodyOpenAnswers: any = [];
                bodyOpenAnswers.push(payload.columnsOpenAnswers.map((column) => column?.title));

                payload.dataSourceOpenAnswers.forEach((data) => {
                    const dataRow: any = [];
                    payload.columnsOpenAnswers.forEach((column) => {
                        const answer = data[column.name] || "Nicht beantwortet";
                        dataRow.push(answer);
                    })
                    bodyOpenAnswers.push(dataRow);
                });
                docDefinition.content.push({
                    pageBreak: "before",
                    text: 'Offene Frage',
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    color: '#000',
                    margin: [0, 7],
                },)
                docDefinition.content.push(
                    {
                        table: {
                            headerRows: 1,
                            body: bodyOpenAnswers
                        },
                        fontSize: 8
                    }
                )

                const bodyGeneral: any = [];
                bodyGeneral.push(payload?.columnsGeneral);




                payload.dataSourceGeneral?.forEach((data) => {
                    const dataRow: any = [];
                    payload.columnsGeneral?.forEach((column) => {
                        //@TODO very strange behaviour. fix later
                        const key = typeof column === 'object' ? column?.text : column
                        dataRow.push(data[key]);
                    })
                    bodyGeneral.push(dataRow);
                });
                docDefinition.content.push({
                    pageBreak: "before",
                    text: 'Allgemeine Ergebnisse',
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    color: '#000',
                    margin: [0, 7],
                },)

                docDefinition.content.push(
                    {
                        table: {
                            headerRows: 1,
                            body: bodyGeneral
                        },
                        fontSize: 8
                    }
                );
            } catch (error) {
                console.error('Error:', error);
            } finally {
                console.log("DOC DEFINITION>>>", docDefinition)
                payload.togglePdfLoading(false);
                pdfMake.createPdf(docDefinition).open();
                // window.open(URL.createObjectURL(pdf.output('blob')));
            }
        }, 0)
    }

    private getGeneralInfoString(introduction: string, entity: string) {
        return {
            text: `${introduction} ${entity}`,
            fontSize: 16,
            alignment: 'center',
            color: '#000',
            margin: [0, 20],
            bold: true,
        }
    }

    private getSummaryString(text: string, isLast?: boolean) {
        return {
            text,
            margin: [0, 10],
            fontSize: 12,
            pageBreak: isLast ? 'after' : 'none'
        }
    }

    private getDefaultLogoForDoc(logo: string) {
        return {url: logo || DEFAULT_LOGO}
    }

    private getLogoString() {
        return {image: "logo", width: 200, alignment: 'center', margin: [0, 20]};
    }
}