import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";
import {Evaluation} from "../model/Evaluation";
import {ContextService} from "../services/context.service";
import {CustomizationService} from "../services/customization.service";
import {EvaluationService} from "../services/evaluation.service";



@Component({
    selector: 'app-token-login',
    templateUrl: './token-login.component.html',
    styleUrls: ['./token-login.component.css']
})
export class TokenLoginComponent implements OnInit {

    evaluations: Evaluation[];
    chosenEvaluationFromList: Evaluation;
    chosenEvaluation: Evaluation;

    constructor(public authenticationService: AuthenticationService,
                public context: ContextService,
                private route: ActivatedRoute,
                private customizationService: CustomizationService,
                private evaluationService: EvaluationService,
    ) {
    }

    ngOnInit(): void {
        this.context.requestedEvaluationID = String(this.route.snapshot.queryParamMap.get('id'));
        const token = String(this.route.snapshot.queryParamMap.get('token'));
        if (token && this.context.requestedEvaluationID !== String(null)) {
            this.evaluationService.getEvaluationById(this.context.requestedEvaluationID).subscribe(result =>{
                this.context.activeEvaluation = result
                this.authenticationService.loginViaToken(token, result);
                this.customizationService.setStyles()
            })
        }
    }

    receiveEvaluationFromList(event: Evaluation) {
        this.chosenEvaluationFromList = event
        this.customizationService.setStyles()
    }


    protected readonly String = String;
}
