<app-sidebar>
    <div class="container">
        <h1>{{'organizationDetails' | translate}}</h1>
        <div class="metadata-container">
            <div class="sub-container">
                <div class="buttons-plus-name-container">
                    <h2 class="name">{{organization?.name}}</h2>
                    <div class="buttons">
                        <button class="button" mat-raised-button color="primary" (click)="goToOrganizationEditing()">{{'edit' | translate}}</button><br>
                        <button class="button" mat-raised-button color="primary">{{'delete' | translate}}</button>
                    </div>
                </div>
                <div class="inline-fields">
                    <div class="one-third-width">
                        <span>{{organization?.type}}</span><br>
                        <span>{{organization?.shortname}}</span><br>
                        <span>{{organization?.address}}</span><br>
                        <span>{{organization?.postcode}} {{organization?.city}}</span><br>
                    </div>
                    <div class="one-third-width">
                        <strong>{{'contactPerson' | translate}}</strong><br>
                        <span>{{organization?.contactpersonfirstname}} {{organization?.contactpersonfamilyname}}</span><br>
                        <span>{{'phoneNumber' | translate}}: {{organization?.phonenumber}}</span><br>
                        <span>{{'email' | translate}}: {{organization?.email}}</span><br>
                    </div>
                    <div class="one-third-width">
                    </div>
                </div>
            </div>
        </div>
        <app-search-bar (SearchTerm)="receiveSearchTerm($event)" ></app-search-bar>
        <div class="button-container">
            <button id="create-organization-button" mat-raised-button color="primary" (click)="goToFacilityCreation()">{{'createFacility' | translate}}</button>
        </div>
        <app-facilities-list [searchTerm]="this.searchTerm" [facilities]="this.organization?.facilities"></app-facilities-list>
    </div>
</app-sidebar>
