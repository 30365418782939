<app-sidebar>
    <div class="container">
        <h1>{{'facilityDetails' | translate}}</h1>
        <div class="metadata-container">
            <div class="sub-container">
                <div class="buttons-plus-name-container">
                    <h2 class="name">{{facility?.name}}</h2>
                    <div class="buttons">
                        <button class="button" mat-raised-button color="primary" (click)="goToFacilityEditing()">{{'edit' | translate}}</button><br>
                        <button class="button" mat-raised-button color="primary">{{'delete' | translate}}</button>
                    </div>
                </div>
                <div class="inline-fields">
                    <div class="one-third-width">
                        <span>{{facility?.type}}</span><br>
                        <span>{{facility?.shortname}}</span><br>
                        <span>{{facility?.address}}</span><br>
                        <span>{{facility?.postcode}} {{facility?.city}}</span><br>
                    </div>
                    <div class="one-third-width">
                        <strong>{{'contactPerson' | translate}}</strong><br>
                        <span>{{facility?.contactpersonfirstname}} {{facility?.contactpersonfamilyname}}</span><br>
                        <span>{{'phoneNumber' | translate}}: {{facility?.phonenumber}}</span><br>
                        <span>{{'email' | translate}}: {{facility?.email}}</span><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-sidebar>

