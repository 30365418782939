import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {EvaluationService} from "../../../services/evaluation.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {WarningModalDialogComponent} from "../../../warning-modal-dialog/warning-modal-dialog.component";

@Component({
  selector: 'app-publish-evaluation',
  templateUrl: './publish-evaluation.component.html',
  styleUrls: ['./publish-evaluation.component.css']
})
export class PublishEvaluationComponent implements OnInit {

  @Input() createdEvaluationId: string;
  // @Input() createdEvaluationSurvey: string;
  @Input() updatedEvaluationId: string;
  // @Input() updatedEvaluationSurvey: string;
  @Input() createdEvaluationFacilities: any;
  @Input() updatedEvaluationFacilities: any;

  @Output() TokensGenerated = new EventEmitter<Array<any>>
  @Output() EvaluationId =new EventEmitter<string>

  constructor(private evaluationService: EvaluationService,
              public matDialog: MatDialog){}

  published = false;
  tokensGenerated = [];

  ngOnInit() {
  }

  onPublishEvaluation(){
    if(this.createdEvaluationId){

      this.evaluationService.changeEvaluationState(this.createdEvaluationId, 'PUBLISHED').subscribe(response =>{
        this.published = true;
      })
    }
    else if(this.updatedEvaluationId){
      this.evaluationService.changeEvaluationState(this.updatedEvaluationId, 'PUBLISHED').subscribe(response =>{
        this.published = true;
      })
    }

  }

  onGenerateTokens(facilityId: number) {
    if(this.createdEvaluationId){
      this.evaluationService.generateTokens(Number(this.createdEvaluationId), facilityId).subscribe( response =>{
        this.tokensGenerated = response;
        this.TokensGenerated.emit(this.tokensGenerated)
        this.EvaluationId.emit(this.createdEvaluationId)
      })
    }
    else if(this.updatedEvaluationId){
      this.evaluationService.generateTokens(Number(this.updatedEvaluationId), facilityId).subscribe( response =>{
        this.tokensGenerated = response;
        this.TokensGenerated.emit(this.tokensGenerated)
        this.EvaluationId.emit(this.createdEvaluationId)
      })
    }
  }


  openModal(message: string, warning: string, buttonName: string,  evaluationId: string, type: string) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: message,
      warning: warning,
      buttonName: buttonName,
      onModalClick: ()=>this.onPublishEvaluation()
    }
    const modalDialog = this.matDialog.open(WarningModalDialogComponent, dialogConfig);
  }

}
