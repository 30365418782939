
<label for="field" class="field-label-2" >{{'loginWithEmail' | translate}}</label>
<input  type="text" name="asdad" [(ngModel)]="email" class="textfield w-input" maxlength="256" name="field" data-name="Field" placeholder="{{'email' | translate }}" id="field" required="true">
<label for="field1" class="field-label-2"></label>
<input  type="password" name="asdad" [(ngModel)]="password" class="textfield w-input" maxlength="256" name="field" data-name="Field" placeholder="{{'password' | translate}}" id="field1" required="true">

<div class="w-layout-hflex flex-block">
    <div >
        <button type="button" class="prim-button w-inline-block buttonLogin" (click)="loginViaEmail()" >{{'login' | translate}}</button>
    </div>
    <div>
        <a (click)="changeLoginOption()" [routerLink]="''" >{{'loginWithTokenLink' | translate}}</a>
    </div>
</div>