import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as validator from "validator";

@Component({
  selector: 'app-list-of-facilities-and-tokens-quantity-chooser',
  templateUrl: './list-of-facilities-and-tokens-quantity-chooser.component.html',
  styleUrls: ['./list-of-facilities-and-tokens-quantity-chooser.component.css']
})
export class ListOfFacilitiesAndTokensQuantityChooserComponent implements OnInit {

  @Input() organization: any
  @Input() onFacilityChange: any;
  @Input() selectedFacilities: Array<any>

  @Output() FacilitiesList = new EventEmitter<any>

  form: FormGroup
  facility: FormGroup
  facilities = new FormControl([])
  arrOfGroups: []
  flag = false
  facilitiesArr: any[] = []

  constructor(private fb: FormBuilder) {
  }


  ngOnInit() {
    this.form = new FormGroup ({
      facilities:   new FormControl( [])
    })
      this.facility = this.fb.group({
        facilityId: ["", Validators.required],
        facilityName:  ["", Validators.required],
        numberOfClients: [0, Validators.required],
        numberOfCustomers: [0, Validators.required],
        numberOfTeamMembers: [0, Validators.required],
        numberOfParents: [0, Validators.required],
      })


    this.onChanges()

  }

  addFacilityToArray() {
    const temp = this.facility.value
    const newFacility = temp;
    Object.keys(newFacility).forEach((key) => {
      newFacility[key] = newFacility[key]?.value
    })
    this.flag = true
    if(temp){
      this.facilitiesArr.push(temp)
    }
  }

  onChanges(){
    this.facility.valueChanges.subscribe(val => {
      const temp = this.facility.value
    })
  }

  test(){
  }




}
