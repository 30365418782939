<div class="popup">
    <h4 class="evaluation_heading" >{{ 'pleaseWriteEvaluationId' | translate }}</h4>
    <div class="w-layout-hflex flex-block">
        <label id="evaluation-id"  ></label>
        <app-evaluation-list (ChosenEvaluation)="receiveEvaluationFromList($event)"></app-evaluation-list>
        <button (click)="onEvaluationIdSelect()" class="prim-button disabled_button1" [disabled]="!evaluationId" >{{ 'submit' | translate }}</button>
    </div>
</div>




