import {Component, Input, OnInit} from '@angular/core';
import {FacilitiesService} from "../../services/facilities.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {OrganizationService} from "../../services/organization.service";

@Component({
  selector: 'app-facilities-list',
  templateUrl: './facilities-list.component.html',
  styleUrls: ['./facilities-list.component.css']
})

export class FacilitiesListComponent implements OnInit{

  @Input() searchTerm: string
  @Input() facilities: any

  routeInfo: string = ''
  constructor(private facilitiesService: FacilitiesService,
              private router: Router,
              private route: ActivatedRoute,
              public organizationService: OrganizationService) {
    route.url.subscribe((url) => {
      this.routeInfo = url.join(" ")
    })
  }

  ngOnInit() {
  }


  goToFacilityDetails(facilityId: string){
    this.router.navigate([NavigationService.FACILITY + '/' + facilityId], {relativeTo:this.route})
}


}
