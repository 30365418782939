<div class="login">
    <div class="w-layout-blockcontainer login-box login-box-custom w-container">
        <div class="logo" *ngIf="this.context.activeEvaluation?.logo && !this.authenticationService.loginWithEmail">
            <img src="{{this.context.activeEvaluation.logo}}" loading="lazy" alt="evaluation_logo" class="logo_img" >
        </div>
        <h1 class="heading" *ngIf="!this.authenticationService.loginWithEmail">{{'Welcome' | translate }} </h1>
        <h1 class="heading" *ngIf="this.authenticationService.loginWithEmail">{{'loginWithEmailLink' | translate }} </h1>
        <h4 *ngIf="this.context.activeEvaluation && !this.authenticationService.loginWithEmail" class="heading">{{this.context.activeEvaluation.name }} </h4>
        <div class="textblock">
        </div>
            <div class="w-form">
                <form  id="loginForm" name="wf-form-Email-Form" data-name="Email Form" method="get" class="form" data-wf-page-id="6502c8e77893d87c62e6a2d8" data-wf-element-id="b0027d57-71f1-71db-85a9-5ffe6ad26fb4">
                        <app-evaluation-list *ngIf="this.context.requestedEvaluationID == String(null) && !this.authenticationService.loginWithEmail "
                                             (ChosenEvaluation)="receiveEvaluationFromList($event)">
                        </app-evaluation-list>
                        <app-login-token *ngIf="!this.authenticationService.loginWithEmail"
                                         [evaluations]="evaluations"
                                         [chosenEvaluationFromList]="chosenEvaluationFromList"
                                         [chosenEvaluation]="chosenEvaluation">

                        </app-login-token>
                        <app-login-email *ngIf="this.authenticationService.loginWithEmail"
                                         [evaluations]="evaluations"
                                         [chosenEvaluationFromList]="chosenEvaluationFromList">

                        </app-login-email>
                </form>
        </div>
    </div>
</div>

