import {Component, OnInit} from '@angular/core';
import { Model, StylesManager} from 'survey-angular';
import {TranslateService} from "@ngx-translate/core";
import { jwtDecode } from "jwt-decode";
import {AuthenticationService} from "./services/authentication.service";


StylesManager.applyTheme('modern');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', ]
})
export class AppComponent implements OnInit{
  constructor( private translateService: TranslateService, private authenticationService: AuthenticationService)
  {
    this.translateService.setDefaultLang('de')
    this.translateService.use(localStorage.getItem('lang') || 'de');
  }

  model: Model;

  ngOnInit() {
    if (!this.authenticationService.isAdmin.value){
      const token = localStorage.getItem('access_token') || '';
      if (token && jwtDecode(token)?.['role'] === 'admin'){
        this.authenticationService.isAdmin.next(true);
      }
    }
  }
}
