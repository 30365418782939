import {Component} from '@angular/core';
import { Model } from 'survey-core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})

export class ResultComponent{
  constructor() {
  }

  survey: Model
  evaluationId: string


  protected readonly String = String;
}









