import {Component, Input, OnInit} from '@angular/core';
import {ResultTestFiltersService} from "../../../../services/result-test-filters.service";
import {Facility} from "../../../../model/Facility";

@Component({
    selector: 'result-test-filters',
    templateUrl: 'result-test-filters.component.html',
    styleUrls: ['result-test-filters.component.css'],
})

export class ResultTestFiltersComponent implements OnInit {
    @Input() facilities: Array<any>;
    @Input() entities: Array<string>;
    @Input() tokenDictionary?: Record<string, string>;

    constructor(private filterService: ResultTestFiltersService) {}

    ngOnInit(): void {}

    updateFacilityFilters(facilityId: number, isChecked: boolean): void {
        const currentFilters = this.filterService.filters$.getValue();
        if (isChecked) {
            currentFilters.facilityIds.push(facilityId);
        } else {
            currentFilters.facilityIds = currentFilters.facilityIds.filter(id => id !== facilityId);
        }
        this.filterService.filters$.next(currentFilters);
    }

    updateEntityFilters(entity: string, isChecked: boolean): void {
        const currentFilters = this.filterService.filters$.getValue();
        if (isChecked) {
            currentFilters.entities.push(entity);
        } else {
            currentFilters.entities = currentFilters.entities.filter(e => e !== entity);
        }
        this.filterService.filters$.next(currentFilters);
    }
}