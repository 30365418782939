import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {LoaderService} from "./services/loader.service";
import {ActivatedRoute, Router} from "@angular/router";


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(private loadingService: LoaderService,
              public router: Router,
              private route: ActivatedRoute

) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(this.router.url === '/' || this.router.url.includes('/?')){
          this.totalRequests++;
          this.loadingService.setLoading(true);
          return next.handle(request).pipe(
              finalize(() => {
                  this.totalRequests--;
                  if (this.totalRequests == 0) {
                      this.loadingService.setLoading(false);
                  }
              })
          );
      }
      return next.handle(request)
  }
}