import {StylesManager} from "survey-angular";

export class ColorThemesHardcoded{

    defaultColors = StylesManager.ThemeColors["modern"] ;
    applyGreenTheme(){
        this.defaultColors["$add-button-color"] = "#06831d"
        this.defaultColors["$main-hover-color"] = "#139613"
        // this.defaultColors["$text-color"] = "#0e2505"
        this.defaultColors["$main-color"] = "#0e2505"
        this.defaultColors["$background"] = "#eeffe8"
        this.defaultColors["$sjs-primary-backcolor"] = "#0e2505"
        // StylesManager.applyTheme("modern")
    }

    applyPinkTheme(){
        this.defaultColors["$add-button-color"] = "#da1686"
        this.defaultColors["$main-hover-color"] = "#8d2368"
        // this.defaultColors["$text-color"] = "#80023f"
        this.defaultColors["$main-color"] = "#80023f"
        this.defaultColors["$background"] = "#f8ecf3"
        this.defaultColors["$sjs-primary-backcolor"] = "#80023f"
        // StylesManager.applyTheme("modern")
    }

    applyBlueTheme(){
        this.defaultColors["$add-button-color"] = "#22d4e0"
        this.defaultColors["$main-hover-color"] = "#2f8177"
        // this.defaultColors["$text-color"] = "#004141"
        this.defaultColors["$main-color"] = "#004141"
        this.defaultColors["$background"] = "#e6ffff"
        this.defaultColors["$sjs-primary-backcolor"] = "#004141"
        // StylesManager.applyTheme("modern")
    }

    applyRedTheme(){
        this.defaultColors["$add-button-color"] = "#f34f4f"
        this.defaultColors["$main-hover-color"] = "#ff2525"
        // this.defaultColors["$text-color"] = "#520000"
        this.defaultColors["$main-color"] = "#520000"
        this.defaultColors["$background"] = "#ffe9e9"
        this.defaultColors["$sjs-primary-backcolor"] = "#520000"
        // StylesManager.applyTheme("modern")
    }

    applyOrangeTheme(){
        this.defaultColors["$add-button-color"] = "#5e59c4"
        this.defaultColors["$main-hover-color"] = "#5e59c4"
        // this.defaultColors["$text-color"] = "#6e2700"
        this.defaultColors["$main-color"] = "#1e1a5f"
        this.defaultColors["$background"] = "#ffefec"
        this.defaultColors["$sjs-primary-backcolor"] = "#1e1a5f"
        // StylesManager.applyTheme("modern")
    }
}