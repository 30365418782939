import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationService} from "../../services/organization.service";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-organization-creator',
  templateUrl: './organization-creator.component.html',
  styleUrls: ['./organization-creator.component.css']
})
export class OrganizationCreatorComponent {
  organizationForm: FormGroup
  organizationId: string | null
  organization: any
  organizationForUpdate: any


  constructor(private route: ActivatedRoute,
              private router: Router,
              private organizationService: OrganizationService){
  }

  ngOnInit() {
    this.organizationForm = new FormGroup({
      name: new FormControl('', Validators.required),
      // type: new FormControl('', Validators.required),
      shortname:new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      postcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{5}')]),
      city: new FormControl('', Validators.required),
      contactpersonfirstname: new FormControl('', Validators.required),
      contactpersonfamilyname: new FormControl('', Validators.required),
      phonenumber: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required])
    })
    this.getOrganizationForUpdateId()
    this.getAndUpdateOrganization()
  }

  getOrganizationForUpdateId(){
    this.organizationId = this.route.snapshot.paramMap.get('organizationId');
    return  this.organizationId
  }

  getAndUpdateOrganization(){
    if(this.organizationId){
      this.organizationService.getOrganizationById(this.organizationId).subscribe(response => {
        this.organizationForUpdate = response
        this.organizationForm.patchValue({name: response?.name,
          type: response?.type,
          shortname: response?.shortname,
          address: response?.address,
          postcode: response?.postcode,
          city: response?.city,
          contactpersonfirstname: response?.contactpersonfirstname,
          contactpersonfamilyname: response?.contactpersonfamilyname,
          phonenumber: response?.phonenumber,
          email: response?.email
        })
      })
    }
  }

  handleOrganizationSubmit(){
    if(this.organizationId){
      this.organizationService.updateOrganizationById(this.organizationId, this.organizationForm.value).subscribe( () => {
        this.router.navigate([NavigationService.ORGANIZATIONS]).then()
      })
    }
    else{
      this.organizationService.createOrganization(this.organizationForm.value).subscribe(  () => {
        this.router.navigate([NavigationService.ORGANIZATIONS]).then()
      })
    }
  }
}
