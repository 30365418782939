
<div class="container">
    <div class="card-container card-grid">
        <ng-container *ngFor="let facility of facilities | searchFilter: searchTerm; index as i">
            <mat-card class="card">
                <div class="align_menu">
                    <div class="card-header">
                        <span class="truncate_name">{{ facility.name }}</span>
                    </div>
                    <div class="dropdown">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="goToFacilityDetails(facility.id)">
                                <span>{{'seeDetails' | translate}}</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="card-body">
                    <span>{{facility.address}}</span><br>
                    <span>{{facility.postcode}} {{facility.city}}</span><br>
                    <strong>{{'contactPerson' | translate}}: </strong>
                    <span>{{facility.contactpersonfirstname}} {{facility.contactpersonfamilyname}}</span><br>

                </div>
            </mat-card>
        </ng-container>
    </div>
</div>


